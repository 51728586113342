import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProjectsPanel from 'components/layout/navbar/navbar-projects-panel';
import OrganizationPanel from 'components/layout/navbar/navbar-organization-panel';
import { PanelType } from 'components/layout/navbar/navbar-panel.utils';
import type { Organization } from 'types/api.types';
import styled from 'types/theme.types';

type Props = {
  organization: Organization;
};

const NavbarPanels = ({ organization }: Props) => {
  const { projectId } = useParams();
  const [panel, setPanel] = useState<PanelType>(projectId ? PanelType.Projects : PanelType.Organization);

  useEffect(() => {
    projectId && setPanel(PanelType.Projects);
  }, [projectId]);

  return (
    <PanelsContainer panel={panel}>
      {(organization && (
        <OrganizationPanel organization={organization} onPanelChange={setPanel} currentPanel={panel} />
      )) ||
        null}
      <ProjectsPanel onPanelChange={setPanel} currentPanel={panel} />
    </PanelsContainer>
  );
};

export default NavbarPanels;

const PanelsContainer = styled.div<{ panel: PanelType }>`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  transition: transform 0.15s ease-in-out;
  transform: translateX(calc(100% * ${({ panel }) => -panel}));
`;
