import type { TransientLogForwardingConfiguration } from '@env0/configuration-service/api';
import type { AxiosResponse } from 'axios';
import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { LogForwardingConfig, LogForwardingConfigUpdateRequest } from 'types/api.types';

export default (client: HttpClient) => ({
  getLogForwardingConfig: (organizationId: string) =>
    client.get<LogForwardingConfig>(`log-forwarding/configurations`, { params: { organizationId } }).then(returnData),

  updateLogForwardingConfig: (payload: LogForwardingConfigUpdateRequest) =>
    client.put<TransientLogForwardingConfiguration, AxiosResponse<LogForwardingConfig>>(
      `log-forwarding/configurations`,
      payload
    ),

  deleteLogForwardingConfig: (id: string) => client.delete(`log-forwarding/configurations/${id}`)
});
