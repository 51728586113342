import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { DeploymentAnalyzerApi } from '@env0/deployment-analyzer-service/api';

export default (client: HttpClient) => ({
  getDeploymentAnalyzerResponse: (type: DeploymentAnalyzerApi.DeploymentAnalysisType, deploymentLogId: string) =>
    client
      .get<DeploymentAnalyzerApi.DeploymentAnalyzerResponse>(`deployment-analyzer/${type}/generate`, {
        params: { deploymentLogId }
      })
      .then(returnData)
});
