import { css } from '@emotion/react';
import type { Env0Theme } from 'constants/themes.constants';

interface GetCommonInputCssFromPropsArgs {
  invalid?: boolean;
  theme: Env0Theme;
  fontSize?: number;
  excludeBorderRadius?: boolean;
}

export const getCommonInputCssFromProps = ({
  invalid,
  theme,
  fontSize,
  excludeBorderRadius
}: GetCommonInputCssFromPropsArgs) => css`
  border: 1px solid ${invalid ? theme.errorRed : theme.secondaryBlack};
  ${excludeBorderRadius ? '' : 'border-radius: 4px !important;'}
  font-size: ${fontSize ? fontSize + 'px' : '12px'} !important;
  font-weight: 500 !important;
  box-shadow: none;
  background-color: ${theme.primaryWhite};

  &:disabled {
    background-color: ${theme.primaryGray} !important;
    border-color: ${theme.inputDisabledBorder} !important;
    color: ${theme.inputDisabled} !important;
  }
`;
