import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { TeamApi } from '@env0/team-service/api';
import type { AxiosResponse } from 'axios';

interface GetTeamsParams {
  limit?: string;
  offset?: string;
  name?: string;
  assignedToOrganizationId?: string;
  assignedToProjectId?: string;
  assignedToEnvironmentId?: string;
}

export default (client: HttpClient) => ({
  getTeam: async (teamId: string) => client.get<TeamApi.GetTeam.Response>(`teams/${teamId}`).then(returnData),

  getTeams: async (organizationId: string, params?: GetTeamsParams) =>
    client
      .get<TeamApi.GetTeams.Response>(
        `teams/organizations/${organizationId}`,
        params
          ? {
              params
            }
          : undefined
      )
      .then(returnData),

  createTeam: async (teamRequest: TeamApi.CreateTeam.Request.Body) =>
    client
      .post<TeamApi.CreateTeam.Request.Body, AxiosResponse<TeamApi.CreateTeam.Response>>('teams', teamRequest)
      .then(returnData),

  updateTeam: async (teamId: string, teamRequest: TeamApi.UpdateTeam.Request.Body) =>
    client
      .put<TeamApi.UpdateTeam.Request.Body, AxiosResponse<TeamApi.UpdateTeam.Response>>(`teams/${teamId}`, teamRequest)
      .then(returnData),

  deleteTeam: async (teamId: string) => client.delete<void>(`teams/${teamId}`)
});
