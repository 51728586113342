import { type ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import { type GroupedRowType } from 'utils/table-group-by.utils';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import Info from 'components/common/info';

interface SensitiveVariablesGroupHeaderProps {
  record: GroupedRowType<ConfigurationPropertyStore>;
  titleParts: { text: string; isBold?: boolean }[];
  info?: { id: string; values?: Record<string, any> };
  'data-e2e'?: string;
}

export const VariablesGroupHeader: React.FC<SensitiveVariablesGroupHeaderProps> = ({
  record,
  titleParts,
  info,
  'data-e2e': dataE2e
}) => {
  return (
    <GroupTitle data-e2e={dataE2e}>
      <span>
        {titleParts.map(({ text, isBold }, index) =>
          isBold ? <BoldGroupPart key={index}>{text}</BoldGroupPart> : text
        )}
      </span>
      <ChildrenCounter>{record.children.length}</ChildrenCounter>
      {info && <Info tooltip={<FormattedMessage id={info.id} values={info.values} />} />}
    </GroupTitle>
  );
};

const BoldGroupPart = styled.span`
  font-weight: 600;
`;

const ChildrenCounter = styled.div`
  display: flex;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  background: ${({ theme }) => theme.variableCounterBackground};
  border-radius: 3px;
  padding: 0px 4px 0px 4px;
`;

const GroupTitle = styled.span`
  display: flex;
  font-size: 14px;
  color: ${({ theme }) => theme.variableTitle};
  gap: 5px;
  cursor: pointer;
`;
