import React, { Fragment, useMemo } from 'react';
import type { ActionId, ActionImpl } from 'kbar';
import { Avatar } from 'antd';
import styled from 'types/theme.types';
import { useTheme } from 'hooks/use-theme';

export const KbarResultItem = React.forwardRef(function ResultItem(
  {
    action,
    active,
    currentRootActionId
  }: {
    action: ActionImpl;
    active: boolean;
    currentRootActionId: ActionId;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { isDarkTheme } = useTheme();

  const ancestors = useMemo(() => {
    if (!currentRootActionId) return action.ancestors;
    const index = action.ancestors.findIndex(ancestor => ancestor.id === currentRootActionId);

    return action.ancestors.slice(index + 1);
  }, [action.ancestors, currentRootActionId]);
  return (
    <ResultItemElement ref={ref} active={active}>
      <ItemContent>
        {ancestors.map(ancestor => (
          <Fragment key={ancestor.id}>
            <AncestorNameElement>{ancestor.name}</AncestorNameElement>
            <ArrowElement>&rsaquo;</ArrowElement>
          </Fragment>
        ))}
        {action.icon && <StyledAvatar src={action.icon} shape="square" isDarkTheme={isDarkTheme} size={20} />}
        <span data-e2e={`kbar-action-${action.id}`}>{action.name}</span>
      </ItemContent>
    </ResultItemElement>
  );
});

type ActiveProps = { active?: boolean };

const ResultItemElement = styled.div<ActiveProps>`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: ${({ theme }) => `4px solid ${theme.offWhite}`};
  border-radius: 8px;
  background-color: ${({ active, theme }) => (active ? `${theme.plainGray}` : `inherit`)};
  color: ${({ theme }) => theme.primaryBlack};
`;

const ItemContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
`;

const AncestorNameElement = styled.span`
  opacity: 0.5;
  margin-right: 8px;
`;

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (propName: string) => propName !== 'isDarkTheme'
})<{ isDarkTheme?: boolean }>`
  filter: ${({ isDarkTheme }) => (isDarkTheme ? 'invert(1)' : 'none')};
`;

const ArrowElement = styled.span`
  margin-right: 8px;
`;
