import React from 'react';
import { Avatar } from 'antd';
import Tooltip from 'components/common/tooltip';
import TerraformLogo from 'assets/images/terraform-icon.svg';
import TerragruntLogo from 'assets/images/terragrunt-icon.svg';
import PulumiLogo from 'assets/images/pulumi-logo-on-white.svg';
import HelmLogo from 'assets/images/helm-logo-on-white.svg';
import OpenTofuSvg from 'assets/images/open-tofu.svg';
import K8sLogo from 'assets/images/k8s.svg';
import workflowLogo from 'assets/images/env0-workflow-icon.svg';
import CloudformationSvg from 'assets/images/aws-cloudformation.svg';
import AnsibleSvg from 'assets/images/ansible-logo.svg';
import AnsibleDarkSvg from 'assets/images/ansible-logo-dark.svg';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import styled from 'types/theme.types';
import Link from 'components/common/link';
import VcsIcon from 'components/common/vsc-icon';
import { getEditTemplateUrl } from 'utils/blueprint.utils';
import { getInitialGitProvider, getRepositoryUrl, getShortenRepo } from 'utils/vcs.utils';
import type { Blueprint } from 'types/api.types';
import { useTheme } from 'hooks/use-theme';
import { observer } from 'mobx-react';

export interface TemplateTypeAvatarProps {
  templateType?: BlueprintApi.BlueprintType;
  isSingleUse?: BlueprintApi.Blueprint['isSingleUse'];
  size?: number;
  hideTooltip?: boolean;
  blueprintVcsDetails?: Pick<
    BlueprintApi.Blueprint,
    | 'repository'
    | 'githubInstallationId'
    | 'isGitLab'
    | 'bitbucketClientKey'
    | 'isBitbucketServer'
    | 'isGitLabEnterprise'
    | 'isGitHubEnterprise'
    | 'isAzureDevOps'
  >;
}

interface TemplateTypeAvatarWithLinkProps {
  blueprint?: Blueprint;
}

export const titlePrefix = 'templates.type.tooltip';

const CloudformationLogo: React.FC = () => <ContainedImg src={CloudformationSvg} alt={'CloudFormation logo'} />;
const OpenTofuLogo: React.FC = () => <ContainedImg src={OpenTofuSvg} alt={'OpenTofu logo'} />;

export const templateLogoMapper: Record<BlueprintApi.DeployableType, React.ReactNode> = {
  opentofu: <OpenTofuLogo />,
  module: <OpenTofuLogo />,
  terraform: TerraformLogo,
  terragrunt: TerragruntLogo,
  pulumi: PulumiLogo,
  k8s: K8sLogo,
  cloudformation: <CloudformationLogo />,
  workflow: workflowLogo,
  helm: HelmLogo,
  ansible: AnsibleSvg
};

export const templateLogoDarkMapper: Record<BlueprintApi.DeployableType, React.ReactNode> = {
  ...templateLogoMapper,
  ansible: AnsibleDarkSvg
};

const TemplateTypeAvatarComponent: React.FC<TemplateTypeAvatarProps> = ({
  templateType,
  size,
  hideTooltip,
  blueprintVcsDetails,
  isSingleUse
}) => {
  const { isDarkTheme } = useTheme();
  const vcs = blueprintVcsDetails && getInitialGitProvider(blueprintVcsDetails);
  const vcsLogo = <VcsIcon providerName={vcs} />;
  const typedTemplateType = templateType as BlueprintApi.DeployableType;
  const templateLogo = templateType
    ? isDarkTheme
      ? templateLogoDarkMapper[typedTemplateType]
      : templateLogoMapper[typedTemplateType]
    : TerraformLogo;

  const props = isSingleUse ? { icon: vcsLogo } : { src: templateLogo };
  const templateTitleId = `${titlePrefix}.${templateType ?? 'terraform'}`;

  const withTooltip = (children: React.ReactNode) => (
    <HiddenableTooltip titleId={templateTitleId} placement="top">
      <div>{children}</div>
    </HiddenableTooltip>
  );

  const logoAvatar = <Avatar data-e2e={`${templateType}-icon`} {...props} style={{ height: size, width: size }} />;

  return hideTooltip ? logoAvatar : withTooltip(logoAvatar);
};

TemplateTypeAvatarComponent.displayName = 'TemplateTypeAvatar';
export const TemplateTypeAvatar = observer(TemplateTypeAvatarComponent);

export const TemplateTypeAvatarWithLink: React.FC<TemplateTypeAvatarWithLinkProps> = ({ blueprint }) => {
  if (!blueprint) return null;
  const { type, isSingleUse, id, name, repository } = blueprint;
  const provider = getInitialGitProvider(blueprint);
  const linkProps = isSingleUse
    ? { url: getRepositoryUrl(repository), messageId: getShortenRepo(repository, provider) }
    : { url: getEditTemplateUrl(id), messageId: name };

  return (
    <>
      <AvatarContainer>
        <TemplateTypeAvatar
          templateType={type}
          blueprintVcsDetails={blueprint}
          isSingleUse={isSingleUse}
          hideTooltip={isSingleUse}
        />
      </AvatarContainer>
      <BlueLink plainText={true} {...linkProps} data-e2e={'repo-link'} />
    </>
  );
};

const ContainedImg = styled.img`
  object-fit: contain !important;
`;

const HiddenableTooltip = styled(Tooltip)<{ hideTooltip?: boolean }>`
  display: ${props => (props.hideTooltip ? 'none' : 'auto')};
`;

const BlueLink = styled(Link)`
  color: ${props => props.theme.primaryBlue} !important;
`;

const AvatarContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
`;
