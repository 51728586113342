import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { CloudResourceApi } from '@env0/cloud-resource-service/api';

export default (client: HttpClient) => ({
  getCloudTrends: (params: CloudResourceApi.FindTrendData.Request.QueryParams) =>
    client
      .get<CloudResourceApi.FindTrendData.Response>('cloud/trends', {
        params
      })
      .then(returnData)
});
