import React from 'react';
import { FormattedMessage } from 'react-intl';
import ControlledRadioGroup from 'components/common/form-controlled/controlled-radio-group';
import styled from 'types/theme.types';
import theme from 'constants/themes.constants';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { ComingSoonTag } from 'components/common/coming-soon-tag';

const RadioGroupContainer = styled.div`
  margin: 16px 0;
`;

const driftRemediationDisabled = 'DISABLED';

const items = [
  { value: driftRemediationDisabled, content: <FormattedMessage id="drift-remediation.option.disabled" /> },
  { value: 'CODE_TO_CLOUD', content: <FormattedMessage id="drift-remediation.option.code-to-cloud" /> },
  {
    value: 'CLOUD_TO_CODE',
    disabled: true,
    content: (
      <>
        <FormattedMessage id="drift-remediation.option.cloud-to-code" />
        <ComingSoonTag color={theme.lightGreen}>
          <FormattedMessage id="coming-soon" />
        </ComingSoonTag>
      </>
    )
  }
];

export const ControlledDriftRemediationSettingsField: React.FC<{
  form: UseFormReturnType;
  disabled?: boolean;
}> = ({ form, disabled }) => {
  const value = disabled ? driftRemediationDisabled : form.watch('autoDriftRemediation');

  return (
    <RadioGroupContainer>
      <ControlledRadioGroup form={form} name={'autoDriftRemediation'} items={items} disabled={disabled} value={value} />
    </RadioGroupContainer>
  );
};
