import useApiClient from 'hooks/use-api-client';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import type { TeamApi } from '@env0/team-service/api';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { safeOffset } from 'stores/rq/common/react-query-utils';

const DEFAULT_TEAMS_PAGE_SIZE = 20;

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'teams'] as const;
  return {
    byQueryParams: (opts: UseTeamsOptions) => {
      const { teamName, assignedToOrganizationId, assignedToProjectId, assignedToEnvironmentId } = opts;
      return [
        ...baseKey,
        'all',
        { teamName },
        { assignedToOrganizationId, assignedToProjectId, assignedToEnvironmentId }
      ] as const;
    }
  } as const;
};

export interface UseTeamsOptions {
  limit?: number;
  teamName?: string;
  assignedToOrganizationId?: string;
  assignedToProjectId?: string;
  assignedToEnvironmentId?: string;
}

export const useGetTeams = (opts: UseTeamsOptions = {}) => {
  const {
    limit = DEFAULT_TEAMS_PAGE_SIZE,
    teamName,
    assignedToOrganizationId,
    assignedToProjectId,
    assignedToEnvironmentId
  } = opts;

  const apiClient = useApiClient();
  const { byQueryParams } = useCacheKeys();

  return useInfiniteQuery({
    queryKey: byQueryParams({ teamName, assignedToOrganizationId, assignedToProjectId, assignedToEnvironmentId }),
    queryFn: async ({ pageParam, queryKey: [orgId] }) => {
      const teamsResponse = await apiClient.teams.getTeams(orgId, {
        limit: limit.toString(),
        offset: safeOffset(pageParam),
        name: teamName,
        assignedToOrganizationId,
        assignedToProjectId,
        assignedToEnvironmentId
      });
      return teamsResponse as { teams: TeamApi.Team[]; nextPageKey?: string };
    },
    select: data => data?.pages?.flatMap(page => page.teams),
    refetchOnMount: 'always',
    placeholderData: keepPreviousData,
    initialPageParam: '0',
    getNextPageParam: lastPage => {
      return lastPage?.nextPageKey;
    }
  });
};
