import React from 'react';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { Section } from 'components/common/section';
import { FormattedMessage } from 'react-intl';
import ControlledScheduledActionField from 'components/environments/scheduling/controlled-scheduled-action-field';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { usePayGateFeature } from 'hooks/use-pay-gate-feature';
import Card, { CardDescription } from 'components/common/card';
import { ControlledDriftRemediationSettingsField } from 'components/environments/drift-detection/controlled-drift-remediation-settings-field';
import ControlledSaveDiscardButtons from 'components/common/form-controlled/controlled-save-discard-buttons';

const CardHeader: React.FC = () => (
  <>
    <FormattedMessage id={'projects.settings.policies.drift-detection.title'} />
    <CardDescription>
      <FormattedMessage
        id={'projects.settings.policies.drift-detection.explanation'}
        values={setLink(links.docs.DRIFT_DETECTION.ROOT)}
      />
    </CardDescription>
  </>
);

const DriftDetectionSettings: React.FC<{ form: UseFormReturnType }> = ({ form }) => {
  const { activated, payGateTitleId } = usePayGateFeature('driftDetection');
  const driftDetectionEnabled = form.watch('driftDetection.enabled');
  const isDriftRemediationDisabled = !activated || !driftDetectionEnabled;

  return (
    <Card title={<CardHeader />}>
      <Section titleId="environment.drift.detection.title">
        <FormattedMessage id="environment.drift.detection.hint" />
        <ControlledScheduledActionField
          form={form}
          tooltipTextId={payGateTitleId}
          disabled={!driftDetectionEnabled && !activated}
          data-e2e="policy-field-drift-detection"
          type="DRIFT_DETECTION"
          isAuthorized={true}
          cronPropertyName="driftDetection"
          infoTooltip={
            <FormattedMessage
              id="environment.drift.detection.description"
              values={setLink(links.docs.DRIFT_DETECTION.ROOT)}
            />
          }
        />
      </Section>
      <Section titleId="projects.settings.policies.drift-detection.drift-remediation.title">
        <FormattedMessage
          id="projects.settings.policies.drift-detection.drift-remediation.explanation"
          values={setLink(links.docs.DRIFT_DETECTION.AUTOMATIC_DRIFT_REMEDIATION)}
        />
        <ControlledDriftRemediationSettingsField form={form} disabled={isDriftRemediationDisabled} />
      </Section>
      <ControlledSaveDiscardButtons
        saveButtonDataE2E="project-settings-policies-drift-detection-settings-save"
        cancelButtonDataE2E="project-settings-policies-drift-detection-settings-discard"
        form={form}
        cancelMessageId={'discard.changes'}
        inContainer={true}
      />
    </Card>
  );
};

export default DriftDetectionSettings;
