import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Collapse from 'components/common/collapse-components/collapse';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'types/theme.types';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';
import { FieldRow, Info, Label, LabelAndIcon, Text } from 'components/common/form-components';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledTextArea from 'components/common/form-controlled/controlled-text-area';
import ControlledRadioButtons from 'components/common/form-controlled/controlled-radio-buttons';
import { TemplateTypeAvatar } from 'components/common/template-type-avatar';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import SshKeysField from 'components/templates/templates-wizard/settings/ssh-keys-field';
import RetryField from 'components/templates/templates-wizard/settings/retry-field';
import { Alert, Col, Collapse as antdCollapse, Row, Typography } from 'antd';
import type { SettingsForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import { TerraformVersionField } from 'components/templates/templates-wizard/settings/terraform-version-field';
import { TerragruntVersionField } from 'components/templates/templates-wizard/settings/terragrunt-version-field';
import { SimpleVersionField } from 'components/templates/templates-wizard/settings/simple-version-field';
import ControlledCheckbox from 'components/common/form-controlled/controlled-checkbox';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { OpentofuVersionField } from './opentofu-version-field';
import OpenTofuSvg from 'assets/images/open-tofu.svg';
import { TerragruntTfBinaryField } from 'components/templates/templates-wizard/settings/terragrunt-tf-binary-field';
import type { ConfigurationTemplateWithoutName } from 'types/api.types';
import { useModal } from 'hooks/modal.hooks';
import { VcsTemplateModal, type VcsTemplateModalProps } from 'components/templates/vcs-template-modal';
import isNull from 'lodash/isNull';
import ConfigurationTemplatesTable from 'components/policies/configuration-templates-table';
import type { SimpleVersionIacType } from 'components/templates/templates-wizard/settings/version-field';
import { usePayGateFeature } from 'hooks/use-pay-gate-feature';
import type { RadioButtonOpts } from 'components/common/radio-buttons';

const { Panel } = antdCollapse;

const DEFAULT_TEMPLATES: BlueprintApi.AllDeployableTypes = [
  'opentofu',
  'terraform',
  'terragrunt',
  'pulumi',
  'k8s',
  'cloudformation',
  'helm',
  'ansible',
  'workflow'
];

const advancedCollapseKey = 'advanced';
const templatePrefix = 'templates.add.new.field';
const singleUsePrefix = 'templates.singleUse';
export const templateTypesWithoutAdvancedSection = new Set<BlueprintApi.BlueprintType>(['workflow']);

type SettingsStepProps = SubFormProps<SettingsForm> & {
  isEdit: boolean;
  isSingleUse?: boolean;
  templateTypes?: BlueprintApi.BlueprintType[];
  showApprovalPolicies?: boolean;
};
const TemplateWizardStepSettings: React.FC<SettingsStepProps> = ({
  form,
  isEdit,
  isSingleUse,
  templateTypes,
  showApprovalPolicies = true
}) => {
  templateTypes = templateTypes ?? DEFAULT_TEMPLATES;
  const intl = useIntl();
  const onPanelChange = useCallback((key: string | string[]) => setActivePanel(key), []);
  const templateType = form.watch('type');
  const shouldHideAdvancedSection = templateTypesWithoutAdvancedSection.has(templateType);
  const terragruntTfBinary = form.watch('terragruntTfBinary');
  const [approvalPoliciesAssignments, setApprovalPoliciesAssignments] =
    form.useManualField<BlueprintApi.ApprovalPolicyTemplateWithScope[]>('approvalPoliciesAssignments');
  const { hideModal, isModalOpen, showModal } = useModal();
  const [modifiedAssignmentIndex, setModifiedAssignmentIndex] = useState<number | null>(null);
  const [activePanel, setActivePanel] = useState<string | string[] | undefined>(() =>
    isEdit && !shouldHideAdvancedSection ? advancedCollapseKey : undefined
  );
  const { activated: workflowTypeEnabled, payGateTitleId } = usePayGateFeature('workflows');

  useEffect(() => {
    if (shouldHideAdvancedSection) {
      setActivePanel(undefined);
    }
  }, [shouldHideAdvancedSection]);

  const onCreate = () => {
    showModal();
  };

  const onUpdate = ({ index: assignmentFormIndex }: { index: number }) => {
    setModifiedAssignmentIndex(assignmentFormIndex);
    showModal();
  };

  const onDelete = ({ index: assignmentFormIndex }: { index: number }) => {
    setApprovalPoliciesAssignments(
      approvalPoliciesAssignments.filter((_: any, index: number) => index !== assignmentFormIndex)
    );
  };

  const onVcsModalSave = (approvalPolicyBlueprintDetails: ConfigurationTemplateWithoutName) => {
    if (!isNull(modifiedAssignmentIndex)) {
      const updatedAssignments = [...approvalPoliciesAssignments];
      updatedAssignments[modifiedAssignmentIndex].blueprint = {
        ...updatedAssignments[modifiedAssignmentIndex].blueprint,
        ...approvalPolicyBlueprintDetails
      } as BlueprintApi.ApprovalPolicyTemplate;

      setApprovalPoliciesAssignments(updatedAssignments);

      setModifiedAssignmentIndex(null);
    } else {
      setApprovalPoliciesAssignments([
        ...approvalPoliciesAssignments,
        {
          blueprint: approvalPolicyBlueprintDetails,
          scope: 'BLUEPRINT'
        } as BlueprintApi.ApprovalPolicyTemplateWithScope
      ]);
    }
  };

  const onVcsModalClose = () => {
    setModifiedAssignmentIndex(null);
    hideModal();
  };

  const templateTypeButtons = useMemo(() => {
    return templateTypes?.map(type => {
      const additionProps: Partial<RadioButtonOpts> = {};

      if (type === 'workflow' && !workflowTypeEnabled) {
        additionProps.disabled = true;
        additionProps.ribbonText = 'Enterprise';
        additionProps.tooltipId = payGateTitleId;
      }

      return {
        value: type,
        content: (
          <TemplateTypeButtonsContainer>
            <TemplateTypeAvatar templateType={type} hideTooltip size={28} />
            <TemplateTypeText>
              <FormattedMessage id={type} />
            </TemplateTypeText>
          </TemplateTypeButtonsContainer>
        ),
        ...additionProps
      };
    });
  }, [payGateTitleId, workflowTypeEnabled, templateTypes]);

  const prefix = isSingleUse ? singleUsePrefix : templatePrefix;

  return (
    <div>
      <ControlledRadioButtons
        label={{ id: `${prefix}.type` }}
        mandatory
        name="type"
        form={form}
        buttons={templateTypeButtons}
        data-e2e="template-type-input"
        size="huge"></ControlledRadioButtons>
      {!isSingleUse && (
        <FieldRow>
          <ControlledTextInput
            inline
            width={50}
            mandatory
            label={{ id: `${templatePrefix}.name` }}
            name="name"
            form={form}
            data-e2e="template-name-input"
            autoFocus
          />
          <ControlledTextArea
            inline
            width={50}
            label={{ id: 'description' }}
            name="description"
            placeholder={intl.formatMessage({ id: `${templatePrefix}.description.placeholder` })}
            data-e2e="template-description-input"
            form={form}
          />
        </FieldRow>
      )}
      <CollapseContainer hideContent={shouldHideAdvancedSection} data-e2e="advanced-settings-container">
        <Collapse data-e2e="template-wizard-advanced" onChange={onPanelChange} activeKey={activePanel}>
          <Panel
            key={advancedCollapseKey}
            header={
              <Typography.Title level={5}>
                <FormattedMessage id="templates.add.new.divider.advanced" />
              </Typography.Title>
            }>
            <PanelItems>
              {templateType === 'terragrunt' && (
                <Row>
                  <Col span={8}>
                    <ControlledCheckbox name="isTerragruntRunAll" form={form} data-e2e={'enable-run-all-checkbox'}>
                      <Text
                        id={'templates.add.new.field.terragruntRunAll'}
                        info={{
                          id: 'templates.add.new.field.terragruntRunAll.info',
                          values: setLink(links.TERRAGRUNT_DOCS.RUN_ALL)
                        }}
                      />
                    </ControlledCheckbox>
                  </Col>
                </Row>
              )}
              {templateType === 'terraform' && (
                <Row>
                  <Col span={8}>
                    <Alert
                      data-e2e={'opentofu-tagline'}
                      message={
                        <FormattedMessage
                          id={'templates.wizard.step.settings.opentofu-tagline'}
                          values={setLink(links.OPEN_TOFU.ROOT)}
                        />
                      }
                      type="info"
                      showIcon
                      icon={<img width={'24'} src={OpenTofuSvg} alt={'OpenTofu logo'} />}
                    />
                  </Col>
                </Row>
              )}
              {templateType === 'terragrunt' && (
                <Row>
                  <Col span={8}>
                    <TerragruntTfBinaryField form={form} />
                  </Col>
                </Row>
              )}
              {(templateType === 'terraform' ||
                (templateType === 'terragrunt' && terragruntTfBinary === 'terraform')) && (
                <Row>
                  <Col span={8}>
                    <TerraformVersionField form={form} />
                  </Col>
                </Row>
              )}
              {(templateType === 'opentofu' ||
                (templateType === 'terragrunt' && terragruntTfBinary === 'opentofu')) && (
                <Row>
                  <Col span={8}>
                    <OpentofuVersionField form={form} />
                  </Col>
                </Row>
              )}
              {templateType === 'terragrunt' && (
                <Row>
                  <Col span={8}>
                    <TerragruntVersionField form={form} />
                  </Col>
                </Row>
              )}
              {['pulumi', 'ansible'].includes(templateType) && (
                <Row>
                  <Col span={8}>
                    <SimpleVersionField form={form} type={templateType as SimpleVersionIacType} />
                  </Col>
                </Row>
              )}
              <RetryField form={form} label={{ id: 'retry' }} info={{ id: 'retry.info' }} />
              <Row>
                <Col span={8}>
                  <SshKeysField form={form} />
                </Col>
              </Row>
              {showApprovalPolicies && (
                <Row>
                  <Col flex={1}>
                    <LabelAndIcon>
                      <Label id="approval.policies" />
                      <Info information={<FormattedMessage id="templates.approval.policies.info" />} />
                    </LabelAndIcon>
                    <ConfigurationTemplatesTable
                      blueprintFieldName="blueprint"
                      dataE2ePrefix="approval-policy"
                      assignments={approvalPoliciesAssignments!}
                      onCreate={onCreate}
                      onUpdate={onUpdate}
                      onDelete={onDelete}
                    />
                  </Col>
                </Row>
              )}
            </PanelItems>
          </Panel>
        </Collapse>
      </CollapseContainer>
      {isModalOpen && (
        <VcsTemplateModal
          data-e2e="approval-policy-vcs-modal"
          type={'approval-policy'}
          hideModal={onVcsModalClose}
          open={isModalOpen}
          onSave={onVcsModalSave}
          blueprint={
            !isNull(modifiedAssignmentIndex)
              ? (approvalPoliciesAssignments[modifiedAssignmentIndex]?.blueprint as VcsTemplateModalProps['blueprint'])
              : undefined
          }
        />
      )}
    </div>
  );
};

const PanelItems = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
`;

const TemplateTypeText = styled.div`
  margin-left: 5px;
`;

const TemplateTypeButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CollapseContainer = styled.div<{ hideContent: boolean }>`
  visibility: ${({ hideContent }) => (hideContent ? 'hidden' : 'unset')};
`;

export default TemplateWizardStepSettings;
