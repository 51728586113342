import isNil from 'lodash/fp/isNil';
import isBoolean from 'lodash/isBoolean';

interface PopupProps {
  url: string;
  onClosed?: (result?: Record<string, string>) => void;
  onBlock?: () => void;
  features?: {
    [key: string]: string | boolean | number;
  };
  closeByMessage?: string;
}

function getQueryParams(search: string) {
  const params = new URLSearchParams(search);
  return Object.fromEntries([...params]);
}

export class Popup {
  private readonly url: string;
  private readonly onClosed: ((result?: Record<string, string>) => void) | undefined;
  private readonly onBlock: (() => void) | undefined;
  private popup: Window | null = null;
  private windowCheckerInterval: number | null = null;
  private features: any;
  private closeByMessage?: string;

  constructor({ url, onClosed, onBlock, features, closeByMessage }: PopupProps) {
    this.url = url;
    this.onClosed = onClosed;
    this.onBlock = onBlock;
    this.features = features ?? {};
    this.closeByMessage = closeByMessage;

    if (window.top === null) {
      this.features.left = 0;
      this.features.top = 0;
    } else {
      this.features.left = window.top.outerWidth / 2 + window.top.screenX - ((features?.width as number) ?? 700) / 2;
      this.features.top = window.top.outerHeight / 2 + window.top.screenY - ((features?.height as number) ?? 700) / 2;
    }
  }

  static open(props: PopupProps) {
    return new Popup(props).open();
  }

  waitForClose = (event: any) => {
    if (event?.data?.[this.closeByMessage!]) {
      this.close(getQueryParams(event.data.search));
    }
  };

  public open() {
    this.closeByMessage && window.addEventListener('message', this.waitForClose);

    this.popup = window.open(this.url, 'popup', this.toWindowFeatures());

    if (isNil(this.popup)) {
      this.onBlock?.();
      return this;
    }

    this.windowCheckerInterval = window.setInterval(() => {
      if (this.popup?.closed) {
        this.release();
      }
    }, 100);

    return this;
  }

  public close(result?: Record<string, string>) {
    this.popup?.close();
    this.release(result);
  }

  private toWindowFeatures() {
    return Object.keys(this.features)
      .reduce((features: string[], name) => {
        const value = this.features[name];
        if (isBoolean(value)) {
          features.push(`${name}=${value ? 'yes' : 'no'}`);
        } else {
          features.push(`${name}=${value}`);
        }

        return features;
      }, [])
      .join(',');
  }

  private release(result?: Record<string, string>) {
    window.removeEventListener('message', this.waitForClose);
    window.clearInterval(this.windowCheckerInterval as number);
    this.onClosed?.(result);
  }
}
