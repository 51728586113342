import React from 'react';
import { useHasPermission } from 'hooks/use-has-permission';
import RoleSelector from 'components/common/role-selector';
import { useCuratedRoles } from 'stores/rq/roles';
import type { TeamRoleAssignmentRow, UserRoleAssignmentRow } from 'components/common/role-assignments/types';
import type { RolesApi } from '@env0/role-service/api';
import type { Role } from 'types/api.types';
import { ProjectRoles } from 'types/api.types';

interface Props {
  record: TeamRoleAssignmentRow | UserRoleAssignmentRow;
  onChangeAssignmentRole: (teamId: string, role: RolesApi.RBACPermissionRole) => void;
  scopeName: string;
  defaultRoles: Role[];
  tab: 'users' | 'teams';
}

const RoleAssignmentCardRoleSelect: React.FunctionComponent<Props> = ({
  record,
  onChangeAssignmentRole,
  scopeName,
  defaultRoles,
  tab
}) => {
  const { allCustomRoles } = useCuratedRoles();
  const onChange = (role: string) => onChangeAssignmentRole(record.id, role);
  const { isAuthorized: hasEditRolePermission } = useHasPermission(
    scopeName === 'environment' ? 'ASSIGN_ROLE_ON_ENVIRONMENT' : 'EDIT_PROJECT_SETTINGS'
  );

  const isRecordAdmin = (record as UserRoleAssignmentRow).isOrganizationAdmin;
  const isRecordCurrentUser = (record as UserRoleAssignmentRow).isCurrentUser;

  const isInherited = 'inherited' in record && record.inherited;
  const roleEditable = hasEditRolePermission && !isRecordAdmin && !isRecordCurrentUser && !isInherited;

  const role = isRecordAdmin ? ProjectRoles.Admin : record.role;

  return (
    <RoleSelector
      isEditable={roleEditable}
      selectedRole={role}
      displayRole={record.assigned}
      defaultRoles={defaultRoles}
      customRoles={allCustomRoles}
      onChangeRole={onChange}
      tab={tab}
      dataE2ePrefix={scopeName}
    />
  );
};

export default RoleAssignmentCardRoleSelect;
