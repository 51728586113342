import type { Blueprint } from 'types/api.types';
import type { Action } from 'kbar';
import SettingsIcon from 'assets/images/settings-icon.svg';
import GitIcon from 'assets/images/git-dark.svg';
import { getGitRevisionUrl } from 'utils/template.utils';
import ModulesIcon from 'assets/images/terraform-module-icon.svg';

import type { Optional } from 'utility-types';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { templateLogoMapper } from 'components/common/template-type-avatar';

type BlueprintDetailsForRootAction = Pick<Blueprint, 'id' | 'name' | 'type'>;
type BlueprintDetailsForPerformableAction = Omit<BlueprintDetailsForRootAction, 'type'> &
  Pick<Blueprint, 'repository'> &
  Optional<Pick<Blueprint, 'revision' | 'path'> & { urlPrefix: string }>;

export type KindName = 'templates' | 'modules';
export const generatePerformableActions = (
  { id, name: templateName, repository, revision, path, urlPrefix = '' }: BlueprintDetailsForPerformableAction,
  navigate: (path: string) => void,
  kindName: KindName
): Action[] => {
  const performableActions = [
    {
      id: `${id}_SETTINGS`,
      parent: id,
      name: `Settings`,
      keywords: `${kindName} ${templateName} Settings`,
      section: templateName,
      perform: () => navigate(`${urlPrefix}/${kindName}/${id}/settings`),
      icon: SettingsIcon
    },
    {
      id: `${id}_REVIEW_CODE`,
      parent: id,
      name: 'Review Code',
      keywords: `${kindName} ${templateName} Review Code`,
      section: templateName,
      perform: () => window.open(getGitRevisionUrl({ repository, revision, path }), '_blank'),
      icon: GitIcon
    }
  ];

  if (kindName === 'modules')
    performableActions.push({
      id: `${id}_GOTO`,
      parent: id,
      name: 'Goto Module Page',
      keywords: `Go to ${kindName} ${templateName}`,
      section: templateName,
      perform: () => navigate(`${urlPrefix}/${kindName}/${id}`),
      icon: ModulesIcon
    });

  return performableActions;
};

export const generateRootActionForTemplate = (
  { id, name, type }: BlueprintDetailsForRootAction,
  rootId: string,
  section: string
): Action => ({
  id,
  parent: `GENERAL_${rootId}_SECTION`,
  name,
  keywords: `${rootId} ${name}`,
  section,
  icon: type ? templateLogoMapper[type as BlueprintApi.DeployableType] : undefined
});
