import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from 'assets/images/logo-white.svg';
import styled from 'types/theme.types';
import logo3d from 'assets/images/logo-3d.png';
import { ReactComponent as GovernanceIcon } from 'assets/images/governance.svg';
import { ReactComponent as SelfServiceIcon } from 'assets/images/self-service.svg';
import { ReactComponent as IacIcon } from 'assets/images/iac.svg';
import TrustedBy from './trusted-by';

const bullets = [
  { textId: 'iac-automation', Icon: IacIcon },
  { textId: 'rbac-opa', Icon: GovernanceIcon },
  { textId: 'self-service', Icon: SelfServiceIcon }
];

const LoginLeftContent: React.FunctionComponent = () => {
  return (
    <Container>
      <BackgroundLogo src={logo3d} />
      <TopContainer>
        <BigLogo />
        <TagLine>
          <FormattedMessage id="login.tagline" />
        </TagLine>
        <BulletsRow>
          {bullets.map(({ textId, Icon }) => (
            <SingleBullet key={textId}>
              <Icon width={75} height={75} />
              <BulletText>
                <FormattedMessage id={`login.bullets.${textId}`} />
              </BulletText>
            </SingleBullet>
          ))}
        </BulletsRow>
      </TopContainer>
      <TrustedBy />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
`;

const BackgroundLogo = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 55%;
  height: 55%;
  z-index: 10;
  @media (max-width: 768px) {
    width: 100%;
    height: 50%;
  }
`;

const TopContainer = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 75%;
  background: linear-gradient(314.35deg, #161352 -34.97%, #181358 -7.38%, #1d1368 12.23%, #251483 59.51%, #005dd4 100%);
  @media (max-width: 768px) {
    flex-basis: 100%;
  }

  @media (max-width: 1024px) {
    flex-basis: 90%;
  }
`;

const BigLogo = styled(Logo)`
  transform: scale(1.55);
`;

const BulletsRow = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  z-index: 20;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const SingleBullet = styled.div`
  width: 175px;
  padding-bottom: 10px;
  @media (max-width: 1024px) {
    width: 150px;
  }
`;

const TagLine = styled.h1`
  color: ${({ theme }) => theme.textColorInvert};
  width: 350px;
  text-align: center;
  margin-top: 40px;
`;

const BulletText = styled.h3`
  font-size: 16px;
  color: ${({ theme }) => theme.textColorInvert};
  width: fit-content;
`;

export default LoginLeftContent;
