import bitbucketIcon from 'assets/images/login-bitbucket.svg';
import githubIcon from 'assets/images/login-github.svg';
import googleIcon from 'assets/images/login-google.svg';
import microsoftIcon from 'assets/images/login-microsoft.svg';
import { Auth0Lock, Auth0LockPasswordless } from 'auth0-lock';
import { EventNames, getAnonymousId, identify, track } from 'utils/analytics.utils';
import { reportError } from 'utils/sentry.utils';
import history from 'services/history-service';
import { retryStrategies } from '@env0/common-retry';

import {
  auth0Audience,
  auth0ClientId,
  auth0Domain,
  auth0LoginCallbackUrl,
  auth0SsoConnections,
  showUserPasswordLogin,
  stage
} from 'constants/config';

import theme from 'constants/themes.constants';
import { action, computed, observable } from 'mobx';

import BaseService from 'services/base-service';
import BrowserStorage from 'services/BrowserStorage';
import type { Auth0UserProfile } from 'auth0-js';
import type ServiceContainer from 'services/service-container';
import escape from 'escape-html';
import Cookies from 'js-cookie';
import isNil from 'lodash/isNil';
import { NotificationTypes } from 'types/notifications.types';
import merge from 'lodash/merge';

export const LOCK_CONTAINER_ID = 'auth0-lock-container';

export const AUTH0_RETRY_SETTINGS = {
  maxAttempts: 3,
  initialDelay: 200,
  retryPolicy: {
    fieldName: 'code',
    cancelOnErrors: ['login_required']
  }
};

const LAST_LOGIN_METHOD_KEY = 'lastLoginMethod';

const neededKeysInUrlHash = /access_token|id_token/;

const socialButtons = {
  'google-oauth2': {
    displayName: 'Google',
    primaryColor: theme.lighterBlue,
    foregroundColor: theme.primaryWhite,
    icon: googleIcon
  },
  github: {
    displayName: 'GitHub',
    primaryColor: theme.primaryGreen,
    foregroundColor: theme.primaryWhite,
    icon: githubIcon
  },
  windowslive: {
    displayName: 'Microsoft',
    primaryColor: theme.littleBlue,
    foregroundColor: theme.primaryWhite,
    icon: microsoftIcon
  },
  bitbucket: {
    displayName: 'Bitbucket',
    primaryColor: theme.darkBlue,
    foregroundColor: theme.primaryWhite,
    icon: bitbucketIcon
  }
};

export const socialConnections = Object.keys(socialButtons);
if (showUserPasswordLogin) socialConnections.push('Username-Password-Authentication');

export const passwordlessConnections = ['email'];

const allowedConnectionsByLoginMethod: Record<LoginMethod, string[]> = {
  social: socialConnections,
  sso: auth0SsoConnections,
  passwordless: passwordlessConnections
};

export const socialLanguageDictionary = {
  title: 'Get Started For Free',
  loginWithLabel: '%s'
};

export const ssoLanguageDictionary = {
  title: 'Log in to manage your environments',
  invalidErrorHint: 'Invalid email',
  error: {
    login: {
      'hrd.not_matching_email': 'This domain is not registered. Please contact support@env0.com'
    }
  }
};

export const ONLY_POC_ALLOWED = 'Only PoC users are allowed to login using email';
export const passwordlessLanguageDictionary = {
  title: 'Log in to manage your environments',
  invalidErrorHint: 'Invalid email',
  error: {
    passwordless: {
      access_denied: 'Wrong email or verification code',
      // the error code we get from pre-register action when we call `api.access.deny()`
      extensibility_error: ONLY_POC_ALLOWED
    }
  }
};

const languageDictionaryByLoginMethod: Record<LoginMethod, any> = {
  social: socialLanguageDictionary,
  sso: ssoLanguageDictionary,
  passwordless: passwordlessLanguageDictionary
};

export const redirectAfterLoginKey = 'env0redirectAfterLogin';
export const browserStorageKeys = {
  authResult: 'authResult',
  expiresAt: 'expiresAt',
  ssoEmail: 'ssoEmail',
  userProfile: 'userProfile'
};

class AuthenticatedFailed extends Error {
  constructor(authError: { error: string; errorDescription: string }) {
    super(`Authentication Failed: ${authError?.errorDescription} [${authError?.error}]`);
  }
}

export type LoginMethod = 'social' | 'sso' | 'passwordless';

export class AuthStore extends BaseService {
  @observable private accessToken: string | null = null;

  @observable public isAuthenticated = false;
  rememberLastLogin = true;

  private error?: string;

  @observable loginMethod: LoginMethod;

  @computed get isSocialLogin() {
    return this.loginMethod === 'social';
  }

  @computed get isSSOLogin() {
    return this.loginMethod === 'sso';
  }

  @computed get isPasswordlessLogin() {
    return this.loginMethod === 'passwordless';
  }

  _lock: Auth0LockStatic;
  _lockPasswordless?: Auth0LockPasswordlessStatic;

  @computed get lock() {
    if (this.isPasswordlessLogin && !this._lockPasswordless) {
      this._lockPasswordless = new Auth0LockPasswordless(
        `${auth0ClientId}`,
        `${auth0Domain}`,
        merge({}, this.commonOptions, {
          passwordlessMethod: 'code'
        } as Auth0LockPasswordlessConstructorOptions)
      );
    }

    return this.isPasswordlessLogin ? this._lockPasswordless : this._lock;
  }

  setLastLoginMethod = (loginMethod: LoginMethod) => {
    this.loginMethod = loginMethod;
    BrowserStorage.setItem(LAST_LOGIN_METHOD_KEY, loginMethod);
  };

  get commonOptions(): Auth0LockConstructorOptions {
    return {
      closable: false,
      allowAutocomplete: true,
      container: LOCK_CONTAINER_ID,
      configurationBaseUrl: 'https://cdn.auth0.com',
      auth: {
        responseType: 'token id_token',
        redirectUrl: auth0LoginCallbackUrl + 'login',
        params: this.getLockAuthParams(),
        audience: auth0Audience
      },
      showTerms: false,
      theme: {
        logo: '', // don't show the auth0 logo,
        primaryColor: '#3636D8',
        authButtons: socialButtons
      },
      prefill: {
        email: BrowserStorage.getItem(browserStorageKeys.ssoEmail) ?? ''
      }
    };
  }

  constructor(service: ServiceContainer) {
    super(service);

    this.loginMethod = (BrowserStorage.getItem(LAST_LOGIN_METHOD_KEY) as LoginMethod) ?? 'social';
    this._lock = new Auth0Lock(
      `${auth0ClientId}`,
      `${auth0Domain}`,
      merge({}, this.commonOptions, {} as Auth0LockConstructorOptions)
    );
    this._lock.on('authenticated', this.afterSuccessLogin.bind(this));
    this._lock.on('authorization_error', this.onAuthorizationError);
    this._lock.on('unrecoverable_error', this.onAuthorizationError);
  }

  get lastTokenExpirationDate(): number | null {
    const expiresAt = BrowserStorage.getItem(browserStorageKeys.expiresAt);
    return expiresAt ? parseInt(expiresAt) : null;
  }

  get lastAccessTokenIsValid(): boolean {
    return this.lastTokenExpirationDate ? new Date().getTime() < this.lastTokenExpirationDate : false;
  }

  @action
  async afterSuccessLogin(authResult: AuthResult) {
    this.storeLoginData(authResult);
    this.accessToken = authResult.accessToken;
    await this.loadUserData(authResult);
    this.isAuthenticated = true;

    this.rememberLastLogin = true;

    console.log('Succeed to Auth Login');
  }

  @action
  async login() {
    try {
      let authData = BrowserStorage.getItem(browserStorageKeys.authResult);

      if (isNil(authData) || !this.lastAccessTokenIsValid) {
        authData = await this.getAuth0Session();
        this.storeLoginData(authData);
      }

      this.accessToken = authData.accessToken;
      await this.loadUserData(authData);
      this.isAuthenticated = true;

      console.log('Succeed to Login');
    } catch (error) {
      console.log('Failed to login', error);
      this.deleteLoginData();
      this.navigateToLogin();
    }
  }

  async loadUserData(authResult: AuthResult) {
    await this.service.organizationsStore.acceptOrganizationInvitationIfExists();
    const info = await this.getUserInfo();
    info?.email && BrowserStorage.setItem(browserStorageKeys.ssoEmail, info.email);
    this.trackSignup(authResult);
  }

  @action
  async silentRenewSession() {
    try {
      const authData = await this.getAuth0Session();

      this.storeLoginData(authData);
      this.accessToken = authData.accessToken;
      this.isAuthenticated = true;

      console.log('Succeed to renewSession');
    } catch (error) {
      console.log('Failed to renewSession', error);
      this.deleteLoginData();
      this.navigateToLogin();
    }
  }

  navigateToLogin() {
    this.setRedirectToUrl(window.location.pathname + window.location.search);
    history.push({ ...history.location, pathname: '/login', search: '' });
  }

  private storeLoginData(authResult: AuthResult) {
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    BrowserStorage.setItem(browserStorageKeys.expiresAt, expiresAt);
    BrowserStorage.setItem(browserStorageKeys.authResult, authResult);

    console.info('Store Auth data', { authResult, expiresAt });
  }

  private deleteLoginData() {
    BrowserStorage.removeItem(browserStorageKeys.expiresAt);
    BrowserStorage.removeItem(browserStorageKeys.authResult);
    BrowserStorage.removeItem(browserStorageKeys.userProfile);
    this.accessToken = null;
    this.isAuthenticated = false;
    console.info('clear Auth data');
  }

  private async getAuth0Session(): Promise<AuthResult> {
    try {
      const silentAuth = { prompt: 'none' };
      console.info('Checking user session');

      return await retryStrategies.withExponentialBackoff(
        () =>
          new Promise<AuthResult>((resolve, reject) => {
            this.lock?.checkSession(silentAuth, (error, result: AuthResult | undefined) => {
              error && console.log('Got error from auth0', error);
              return error ? reject(error) : resolve(result as AuthResult);
            });
          }),
        AUTH0_RETRY_SETTINGS
      );
    } finally {
      this.clearLeftoverAuth0Cookies(); // After every Auth request, we need to clear the auth0 cookies
    }
  }

  private fetchUserInfo = (accessToken: string): Promise<Auth0UserProfile> => {
    console.info('Fetching user info');
    return retryStrategies.withExponentialBackoff(
      () =>
        new Promise((resolve, reject) => {
          this.lock?.getUserInfo(accessToken, (error, profile) =>
            error ? reject(new Error(`Error fetching auth0 user data`, { cause: error })) : resolve(profile)
          );
        }),
      AUTH0_RETRY_SETTINGS
    );
  };

  private clearLeftoverAuth0Cookies() {
    const cookies = Cookies.get();
    const auth0CookieKeys = Object.keys(cookies).filter(key => key.includes('com.auth0.auth.'));

    track('AUTH0_COOKIES_COUNT', { cookiesCount: auth0CookieKeys.length, cookiesLength: document.cookie.length });
    // Remove all auth0 cookies except the last two
    auth0CookieKeys.slice(0, -2).forEach(key => Cookies.remove(key));
  }

  public getAccessToken() {
    return this.accessToken;
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  onAuthorizationError = (error: any) => {
    reportError('Authentication', new AuthenticatedFailed(error));
    this.rememberLastLogin = false;

    if (this.isPasswordlessLogin && error?.errorDescription === 'invalid_domain') {
      this.error = ONLY_POC_ALLOWED;
    } else {
      this.service.notificationStore.setNotification({
        notificationType: NotificationTypes.error,
        message: error?.errorDescription || error?.message
      });
    }
  };

  trackSignup = (result: AuthResult) => {
    const { idTokenPayload } = result;
    if ((idTokenPayload as any)['https://env0.com/is_first_login']) {
      track(EventNames.SIGNUP);
    }
  };

  getCachedUserProfile = () => {
    const storedProfile = localStorage.getItem(browserStorageKeys.userProfile);
    if (!storedProfile) return null;

    const { data, expiry } = JSON.parse(storedProfile) || {};
    if (Date.now() < expiry) return data;

    localStorage.removeItem(browserStorageKeys.userProfile);
    return null;
  };

  getUserInfo = async () => {
    try {
      const accessToken = this.accessToken;

      if (!accessToken) {
        return;
      }

      let profile = this.getCachedUserProfile();

      if (!profile) {
        profile = await this.fetchUserInfo(accessToken);
        localStorage.setItem(
          browserStorageKeys.userProfile,
          JSON.stringify({
            data: profile,
            expiry: Date.now() + 5 * 60 * 1000
          })
        );
      }

      this.service.userStore.setProfile(profile);

      identify(this.service.userStore.userId as string, profile);
      await this.service.organizationsStore.getOrganizations();
      return profile;
    } catch (error) {
      console.error('Failed to get user info', { cause: error });
      throw error;
    } finally {
      this.clearLeftoverAuth0Cookies();
    }
  };

  logout = (message?: string, returnTo?: string) => {
    const returnToUrl = returnTo || auth0LoginCallbackUrl;
    const returnToWithMessage = message ? `${returnToUrl}?logout_reason=${encodeURI(message)}` : returnToUrl;
    console.info(`Logging out, redirecting to ${returnToWithMessage}`);
    this.lock?.logout({
      returnTo: returnToWithMessage
    });
    this.deleteLoginData();
  };

  isCallbackPage() {
    return neededKeysInUrlHash.test(window.location.hash);
  }

  @action
  show = async () => {
    if (!neededKeysInUrlHash.test(window.location.hash)) {
      this.clearLeftoverAuth0Cookies();
      const searchParams = new URLSearchParams(window.location.search);
      const error = this.error ?? searchParams.get('logout_reason');
      const flashMessage = error
        ? {
            type: 'error' as const,
            text: escape(decodeURI(error))
          }
        : undefined;

      const anonymousId = await getAnonymousId();
      this.lock?.show({
        flashMessage,
        auth: {
          params: this.getLockAuthParams(anonymousId)
        },
        allowedConnections: allowedConnectionsByLoginMethod[this.loginMethod],
        languageDictionary: languageDictionaryByLoginMethod[this.loginMethod],
        rememberLastLogin: this.rememberLastLogin
      });
    }
  };

  @action
  hide = () => {
    this._lock.hide();
    this._lockPasswordless?.hide();
  };

  getRedirectToUrl = () => {
    return BrowserStorage.getItem(redirectAfterLoginKey) || '/';
  };

  setRedirectToUrl = (pathname: string) => {
    if (!pathname?.startsWith('/login')) BrowserStorage.setItem(redirectAfterLoginKey, pathname);
  };

  getLockAuthParams = (anonymousId: string | null = null) => {
    return {
      scope: 'openid profile email',
      stage,
      state: anonymousId ? `anonymousId=${anonymousId}` : undefined,
      user_data: this.getUserData()
    };
  };

  getUserData = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const userData = searchParams.get('userData');
    let returnValue;
    if (userData) {
      try {
        returnValue = JSON.parse(atob(userData));
      } catch (exception) {
        console.error(`Failed to parse userData = ${userData}`);
      }
    }
    return returnValue;
  };
}
