import { ReactComponent as AwsIcon } from 'assets/images/aws-logo.svg';
import { ReactComponent as AwsDarkIcon } from 'assets/images/aws-logo-dark.svg';
import { useTheme } from 'hooks/use-theme';
import { observer } from 'mobx-react';
import type { FC, SVGProps } from 'react';

const AwsLogo: FC<SVGProps<SVGSVGElement>> = props => {
  const { isDarkTheme } = useTheme();
  const IconComponent = isDarkTheme ? AwsDarkIcon : AwsIcon;

  return <IconComponent {...props} />;
};

export default observer(AwsLogo);
