import blueprints from 'services/api-client/blueprints';
import costs from 'services/api-client/costs';
import deployments from 'services/api-client/deployments';
import environments from 'services/api-client/environments';
import projects from 'services/api-client/projects';
import credentials from 'services/api-client/credentials';
import configurations from 'services/api-client/configurations';
import scheduling from 'services/api-client/scheduling';
import organizations from 'services/api-client/organizations';
import teams from 'services/api-client/teams';
import notifications from 'services/api-client/notifications';
import modules from 'services/api-client/modules';
import appNotifications from 'services/api-client/app-notifications';
import dashboards from 'services/api-client/dashboards';
import shared from 'services/api-client/shared';
import agents from 'services/api-client/agents';
import roles from 'services/api-client/roles';
import remoteState from 'services/api-client/remote-state';
import auditLogs from 'services/api-client/audit-logs';
import billing from 'services/api-client/billing';
import type { HttpClient } from 'services/api-client/client';
import { axiosClient } from 'services/api-client/client';
import remoteBackend from 'services/api-client/remote-backend';
import customFlows from 'services/api-client/custom-flows';
import accounts from 'services/api-client/accounts';
import providerRegistry from 'services/api-client/provider-registry';
import permissions from 'services/api-client/permissions';
import bulkOperations from 'services/api-client/bulk-operations';
import approvalPolicies from 'services/api-client/approval-policies';
import environmentOutputs from 'services/api-client/environment-outputs';
import cloudTrends from 'services/api-client/cloud-trends';
import cloudConfigurations from 'services/api-client/cloud-configurations';
import cloudResources from 'services/api-client/cloud-resources';
import environmentImport from 'services/api-client/environment-import';
import driftBlame from 'services/api-client/drift-blame';
import vcsConnections from 'services/api-client/vcs-connections';
import insights from 'services/api-client/insights';
import logForwarding from './log-forwarding';
import deploymentAnalyzer from 'services/api-client/deployment-analyzer';

class ApiClient {
  private readonly client: HttpClient;

  organizations: ReturnType<typeof organizations>;
  shared: ReturnType<typeof shared>;
  scheduling: ReturnType<typeof scheduling>;
  credentials: ReturnType<typeof credentials>;
  configurations: ReturnType<typeof configurations>;
  projects: ReturnType<typeof projects>;
  environments: ReturnType<typeof environments>;
  deployments: ReturnType<typeof deployments>;
  costs: ReturnType<typeof costs>;
  blueprints: ReturnType<typeof blueprints>;
  customFlows: ReturnType<typeof customFlows>;
  remoteBackend: ReturnType<typeof remoteBackend>;
  teams: ReturnType<typeof teams>;
  notifications: ReturnType<typeof notifications>;
  modules: ReturnType<typeof modules>;
  dashboards: ReturnType<typeof dashboards>;
  agents: ReturnType<typeof agents>;
  appNotifications: ReturnType<typeof appNotifications>;
  roles: ReturnType<typeof roles>;
  auditLogs: ReturnType<typeof auditLogs>;
  remoteState: ReturnType<typeof remoteState>;
  billing: ReturnType<typeof billing>;
  accounts: ReturnType<typeof accounts>;
  providerRegistry: ReturnType<typeof providerRegistry>;
  permissions: ReturnType<typeof permissions>;
  bulkOperations: ReturnType<typeof bulkOperations>;
  environmentOutputs: ReturnType<typeof environmentOutputs>;
  approvalPolicies: ReturnType<typeof approvalPolicies>;
  cloudTrends: ReturnType<typeof cloudTrends>;
  cloudConfigurations: ReturnType<typeof cloudConfigurations>;
  cloudResources: ReturnType<typeof cloudResources>;
  environmentImport: ReturnType<typeof environmentImport>;
  driftBlame: ReturnType<typeof driftBlame>;
  vcsConnections: ReturnType<typeof vcsConnections>;
  insights: ReturnType<typeof insights>;
  logForwarding: ReturnType<typeof logForwarding>;
  deploymentAnalyzer: ReturnType<typeof deploymentAnalyzer>;

  constructor(axiosClient: HttpClient) {
    this.client = axiosClient;

    this.organizations = organizations(this.client);
    this.shared = shared(this.client);
    this.scheduling = scheduling(this.client);
    this.credentials = credentials(this.client);
    this.configurations = configurations(this.client);
    this.projects = projects(this.client);
    this.environments = environments(this.client);
    this.deployments = deployments(this.client);
    this.costs = costs(this.client);
    this.blueprints = blueprints(this.client);
    this.customFlows = customFlows(this.client);
    this.remoteBackend = remoteBackend(this.client);
    this.teams = teams(this.client);
    this.notifications = notifications(this.client);
    this.modules = modules(this.client);
    this.dashboards = dashboards(this.client);
    this.agents = agents(this.client);
    this.appNotifications = appNotifications(this.client);
    this.roles = roles(this.client);
    this.auditLogs = auditLogs(this.client);
    this.remoteState = remoteState(this.client);
    this.billing = billing(this.client);
    this.accounts = accounts(this.client);
    this.providerRegistry = providerRegistry(this.client);
    this.permissions = permissions(this.client);
    this.bulkOperations = bulkOperations(this.client);
    this.approvalPolicies = approvalPolicies(this.client);
    this.environmentOutputs = environmentOutputs(this.client);
    this.cloudTrends = cloudTrends(this.client);
    this.cloudConfigurations = cloudConfigurations(this.client);
    this.cloudResources = cloudResources(this.client);
    this.environmentImport = environmentImport(this.client);
    this.driftBlame = driftBlame(this.client);
    this.vcsConnections = vcsConnections(this.client);
    this.insights = insights(this.client);
    this.logForwarding = logForwarding(this.client);
    this.deploymentAnalyzer = deploymentAnalyzer(this.client);
  }
}

export const apiClient = new ApiClient(axiosClient);
export default ApiClient;
