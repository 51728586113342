export const TOP_BAR_HEIGHT = '60px';
export const BREADCRUMB_BAR_HEIGHT = '40px';

export type Env0Theme = {
  mode: 'light' | 'dark';

  // Whites
  primaryWhite: string;
  offWhite: string;
  whiteColor: string;

  // Blacks
  primaryBlack: string;
  secondaryBlack: string;

  // Grays
  primaryGray: string;
  secondaryGray: string;
  lightGray: string;
  subtitleGray: string;
  sidebarHoverGray: string;
  backgroundGray: string;
  lighterBackgroundGray: string;
  textBaseGray: string;
  textGray: string;
  plainGray: string;
  darkGray: string;
  nonEditableBorderGray: string;
  nonEditableBackgroundGray: string;
  separatorGray: string;
  tableHeaderLineGray: string;
  secondaryDisabledGray: string;
  primaryDisabledGray: string;
  iconGrayBackground: string;
  iconGrayFill: string;
  lableBackgroundGray: string;
  lableColorGray: string;
  menuGray: string;
  menuGrayLight: string;
  logoutGray: string;
  modalMaskGray: string;

  // Blues
  subtitleDarkBlue: string;
  primaryBlue: string;
  secondaryBlue: string;
  darkBlue: string;
  lightBlue: string;
  lighterBlue: string;
  littleBlue: string;
  lightBackgroundBlue: string;
  projectBarBlue: string;
  menuItemBlue: string;
  menuItemBlueActive: string;
  skyBlue: string;
  lighterSkyBlue: string;
  backgroundLightBlue: string;
  markdownHeaderBlue: string;
  drawerHeaderBlue: string;

  // Greens
  primaryGreen: string;
  secondaryGreen: string;
  menuItemGreen: string;
  widgetGreen: string;
  lightGreen: string;
  backgroundGreen: string;
  labelGreen: string;
  green: string;

  // Reds
  errorRed: string;
  errorRedHover: string;
  red: string;
  lightRed: string;
  deleteHoverRed: string;
  antdAlertRed: string;

  // Yellows
  warningYellow: string;
  warningYellowDarker: string;
  alertYellow: string;
  alertYellowText: string;

  // Purples
  lightPurple: string;
  brightPurple: string;

  // Cyans
  cyan: string;

  // Side Menu
  sideMenuBackground: string;
  sideMenuBackgroundDark: string;
  sideMenuBorder: string;
  sideMenuText: string;

  // Borders
  defaultShadow: string;
  sidebarBorder: string;
  sidebarSecondaryBorder: string;
  inputDisabledBorder: string;
  primaryDisabledBorder: string;
  defaultBorderRadius: string;

  // Backgrounds
  loginPageBottomPart: string;
  onboardingBackground: string;
  workflowBackground: string;
  workflowBackgroundLighter: string;
  markdownBackground: string;
  lineGraphBackground: string;
  deploymentTypeIconBackground: string;
  kbarSectionBackground: string;
  billingWrapperBackground: string;
  usageCardBackground: string;
  variableCounterBackground: string;
  tooltipBackground: string;
  drawerBackground: string;
  scrollbarThumbBackground: string;
  scrollbarTrackBackground: string;
  deploymentLogDescriptionBackground: string;
  backdrop: string;

  // Text Colors
  textMetalBlue: string;
  textColor: string;
  textColorInvert: string;
  dividerText: string;
  menuItemText: string;
  variableTitle: string;
  tooltipText: string;
  deploymentLogDescriptionInfoText: string;
  deploymentLogDescriptionErrorText: string;
  deploymentLogDescriptionSuccessText: string;
  deploymentLogDescriptionWarningText: string;

  // Input Colors
  inputDisabled: string;

  // Login Colors
  loginBackground: string;

  // Chips
  errorChipBackground: string;
  errorChipText: string;
  warningChipBackground: string;
  warningChipText: string;
  successChipBackground: string;
  successChipText: string;

  // Status Buttons
  statusButtonLightRed: string;
  statusButtonGray: string;
  statusButtonGreen: string;
  statusButtonPurple: string;
  statusButtonRed: string;
  statusButtonYellow: string;

  statusButtonWhiteText: string;
  statusButtonRedText: string;
  statusButtonDarkGrayText: string;
  statusButtonSecondaryGreenText: string;
  statusButtonPrimaryBlueText: string;
  statusButtonAlertYellowText: string;

  // Miscellaneous
  orgSettingsBillingborder: string;
  counterBadgeText: string;
  counterBadgeActiveText: string;
  tabText: string;
  datepickerText: string;
  secondaryHover: string;
  primaryDisabledText: string;
  iconBackground: string;
};

const lightTheme: Env0Theme = {
  mode: 'light',

  // Whites
  primaryWhite: '#FFFFFF',
  offWhite: '#FDFDFD',
  whiteColor: '#FFFFFF',

  // Blacks
  primaryBlack: '#142C48',
  secondaryBlack: '#0E2133',

  // Grays
  primaryGray: '#EBEFF2',
  secondaryGray: '#B7CBD6',
  lightGray: '#CED8E0',
  subtitleGray: '#4D4D4D',
  sidebarHoverGray: '#DCE5EC',
  backgroundGray: '#FAFAFA',
  lighterBackgroundGray: '#F9FAFB',
  textBaseGray: '#000000D9',
  textGray: '#666666',
  plainGray: '#F3F5F7',
  darkGray: '#234D70',
  nonEditableBorderGray: '#D5D5D5',
  nonEditableBackgroundGray: '#ECECEC',
  separatorGray: '#E6E6E6',
  tableHeaderLineGray: '#F0F4F7',
  secondaryDisabledGray: '#727272',
  primaryDisabledGray: '#a0a0a0',
  iconGrayBackground: '#E2E6E9',
  iconGrayFill: '#A6B6BF',
  lableBackgroundGray: '#CFD9EC',
  lableColorGray: '#6D89BE',
  menuGray: '#DFE5F2',
  menuGrayLight: '#F5F8FF',
  logoutGray: '#85A6B9',
  modalMaskGray: '#000000A6',

  // Blues
  subtitleDarkBlue: '#290A89',
  primaryBlue: '#3636D8',
  secondaryBlue: '#343BCD',
  darkBlue: '#25256F',
  lightBlue: '#DFE5F2',
  lighterBlue: '#4285F4',
  littleBlue: '#343BCD',
  lightBackgroundBlue: '#E7F2FB',
  projectBarBlue: '#8C85FF',
  menuItemBlue: '#19375a',
  menuItemBlueActive: '#194270',
  skyBlue: '#B3E2FF',
  lighterSkyBlue: '#E6F6FF',
  backgroundLightBlue: '#FAFAFA',
  markdownHeaderBlue: '#210d72',
  drawerHeaderBlue: '#f3f2ff',

  // Greens
  primaryGreen: '#00D0A2',
  secondaryGreen: '#00AC86',
  menuItemGreen: '#17edbe',
  widgetGreen: '#50CD89',
  lightGreen: '#E6FFF9',
  backgroundGreen: '#00D0A21A',
  labelGreen: '#16B49180',
  green: '#2eb039',

  // Reds
  errorRed: '#FF3939',
  errorRedHover: '#EB3534',
  red: '#DB3131',
  lightRed: '#FFECF0',
  deleteHoverRed: '#EB4A40',
  antdAlertRed: '#fff2f0',

  // Yellows
  warningYellow: '#FFFBBA',
  warningYellowDarker: '#FAAD14',
  alertYellow: '#fdf3cd',
  alertYellowText: '#866709',

  // Purples
  lightPurple: '#F3F3FF',
  brightPurple: '#6726AB',

  // Cyans
  cyan: '#00edb9',

  // Side Menu
  sideMenuBackground: '#152F4D',
  sideMenuBackgroundDark: '#122940',
  sideMenuBorder: '#203955',
  sideMenuText: '#ffffffb3',

  // Borders
  defaultShadow: '-1px 6px 30px 0 rgba(204, 204, 204, 0.5)',
  sidebarBorder: '#234d70',
  sidebarSecondaryBorder: '#203955',
  inputDisabledBorder: '#a0a0a0',
  primaryDisabledBorder: '#a0a0a0',
  defaultBorderRadius: '4px',

  // Backgrounds
  loginPageBottomPart: '#161352',
  onboardingBackground: '#203955',
  workflowBackground: '#0e0f38',
  workflowBackgroundLighter: '#12144a',
  markdownBackground: '#FDFDFD',
  lineGraphBackground: '#FFFFFF',
  deploymentTypeIconBackground: 'transparent',
  kbarSectionBackground: '#FFFFFF',
  billingWrapperBackground: '#FFFFFF',
  usageCardBackground: '#E7F2FB',
  variableCounterBackground: '#CED8E0',
  tooltipBackground: '#142C48',
  drawerBackground: '#FFFFFF',
  scrollbarThumbBackground: '#CED8E0',
  scrollbarTrackBackground: '#FFFFFF',
  deploymentLogDescriptionBackground: '#0E2133',
  backdrop: '#1C2330',

  // Text Colors
  textMetalBlue: '#618DA5',
  textColor: '#0E2133',
  textColorInvert: '#FFFFFF',
  dividerText: '#a0a0a0',
  menuItemText: '#727272',
  variableTitle: '#727272',
  tooltipText: '#FFFFFF',
  deploymentLogDescriptionInfoText: '#E8E8E8',
  deploymentLogDescriptionErrorText: '#FF9699',
  deploymentLogDescriptionSuccessText: '#B2FFBF',
  deploymentLogDescriptionWarningText: '#FFDA96',

  // Input Colors
  inputDisabled: '#727272',

  // Login Colors
  loginBackground: '#FFFFFF',

  // Chips
  errorChipBackground: '#fee2e2',
  errorChipText: '#981a19',
  warningChipBackground: '#fef9c3',
  warningChipText: '#824a08',
  successChipBackground: '#dcfce7',
  successChipText: '#136232',

  // Status Buttons
  statusButtonLightRed: '#FFECF0',
  statusButtonGray: '#F3F5F7',
  statusButtonGreen: '#E6FFF9',
  statusButtonPurple: '#F3F3FF',
  statusButtonRed: '#FF3939',
  statusButtonYellow: '#fdf3cd',

  statusButtonWhiteText: '#FFFFFF',
  statusButtonRedText: '#DB3131',
  statusButtonDarkGrayText: '#234D70',
  statusButtonSecondaryGreenText: '#00AC86',
  statusButtonPrimaryBlueText: '#3636D8',
  statusButtonAlertYellowText: '#866709',

  // Miscellaneous
  orgSettingsBillingborder: '#e0e0f2',
  counterBadgeText: '#B7CBD6',
  counterBadgeActiveText: '#343BCD',
  tabText: '#727272',
  datepickerText: '#00D0A2',
  secondaryHover: '#F7F7F7',
  primaryDisabledText: '#FFFFFF',
  iconBackground: '#E8E8E8'
};

export const darkTheme: Env0Theme = {
  mode: 'dark',

  // Whites
  primaryWhite: '#041016',
  offWhite: '#041016',
  whiteColor: '#FFFFFF',

  // Blacks
  primaryBlack: '#FFFFFF',
  secondaryBlack: '#636366',

  // Grays
  primaryGray: '#282E3A',
  secondaryGray: '#393F4D',
  lightGray: '#636366',
  subtitleGray: '#D1D1D6',
  sidebarHoverGray: '#38383A',
  backgroundGray: '#041016',
  lighterBackgroundGray: '#181818',
  textBaseGray: '#E5E5EA',
  textGray: '#BEBEC2',
  plainGray: '#10253C',
  darkGray: '#041016',
  nonEditableBorderGray: '#4A4A4C',
  nonEditableBackgroundGray: '#2C2C2E',
  separatorGray: '#282E3A',
  tableHeaderLineGray: '#2E2E30',
  secondaryDisabledGray: '#364352',
  primaryDisabledGray: '#364352',
  iconGrayBackground: '#E2E6E9',
  iconGrayFill: '#A6B6BF',
  lableBackgroundGray: '#3E3E50',
  lableColorGray: '#8E99C6',
  menuGray: '#28282A',
  menuGrayLight: '#1F1F21',
  logoutGray: '#5C778A',
  modalMaskGray: '#000000CC',

  // Blues
  subtitleDarkBlue: '#AEB7FF',
  primaryBlue: '#8585FF',
  secondaryBlue: '#afafe7',
  darkBlue: '#0F0F3C',
  lightBlue: '#1A3A5A',
  lighterBlue: '#0056C8',
  littleBlue: '#171788',
  lightBackgroundBlue: '#041016',
  projectBarBlue: '#4F4FC3',
  menuItemBlue: '#10253C',
  menuItemBlueActive: '#0F2C48',
  skyBlue: '#598DFF',
  lighterSkyBlue: '#163B57',
  backgroundLightBlue: '#252B36',
  markdownHeaderBlue: '#8585FF',
  drawerHeaderBlue: '#0F2C48',

  // Greens
  primaryGreen: '#007F67',
  secondaryGreen: '#005A45',
  menuItemGreen: '#0FC2A2',
  widgetGreen: '#3CA774',
  lightGreen: '#1B4D43',
  backgroundGreen: '#00D0A21A',
  labelGreen: '#16B49180',
  green: '#0E8045',

  // Reds
  errorRed: '#E63939',
  errorRedHover: '#C83534',
  red: '#A52A2A',
  lightRed: '#5F2C2C',
  deleteHoverRed: '#C84C40',
  antdAlertRed: '#281416',

  // Yellows
  warningYellow: '#665C1E',
  warningYellowDarker: '#D49D00',
  alertYellow: '#4D4535',
  alertYellowText: '#C3A506',

  // Purples
  lightPurple: '#3B3B6D',
  brightPurple: '#6726AB',

  // Cyans
  cyan: '#00A887',

  // Side Menu
  sideMenuBackground: '#01141D',
  sideMenuBackgroundDark: '#0A0B0E',
  sideMenuBorder: '#1E1E1F',
  sideMenuText: '#A3A3A3',

  // Borders
  defaultShadow: '0 12px 30px rgba(29, 19, 109, 0.12)',
  sidebarBorder: '#1C1C1D',
  sidebarSecondaryBorder: '#141415',
  inputDisabledBorder: '#364352',
  primaryDisabledBorder: '#5C6978',
  defaultBorderRadius: '4px',

  // Backgrounds
  loginPageBottomPart: '#06062C',
  onboardingBackground: '#1C1C2E',
  workflowBackground: '#144E9D',
  workflowBackgroundLighter: '#1B5FCC',
  markdownBackground: '#252B36',
  lineGraphBackground: '#252B36',
  deploymentTypeIconBackground: '#E8E8E8',
  kbarSectionBackground: '#01141D',
  billingWrapperBackground: '#282E3A',
  usageCardBackground: '#282E3A',
  variableCounterBackground: '#10253C',
  tooltipBackground: '#e0e8fa',
  drawerBackground: '#282E3A',
  scrollbarThumbBackground: '#041016',
  scrollbarTrackBackground: '#636366',
  deploymentLogDescriptionBackground: '#3F4756',
  backdrop: '#1C2330',

  // Text Colors
  textMetalBlue: '#93B3CF',
  textColor: '#FFFFFF',
  textColorInvert: '#FFFFFF',
  dividerText: '#636366',
  menuItemText: '#FFFFFF',
  variableTitle: '#BEBEC2',
  tooltipText: '#000e14',
  deploymentLogDescriptionInfoText: '#E8E8E8',
  deploymentLogDescriptionErrorText: '#FF9699',
  deploymentLogDescriptionSuccessText: '#B2FFBF',
  deploymentLogDescriptionWarningText: '#FFDA96',

  // Input Colors
  inputDisabled: '#606E7E',

  // Login Colors
  loginBackground: '#282E3A',

  // Chips
  errorChipBackground: '#4D2C2C',
  errorChipText: '#CC6060',
  warningChipBackground: '#4D4535',
  warningChipText: '#C3A506',
  successChipBackground: '#2C3C2C',
  successChipText: '#60CC60',

  // Status Buttons
  statusButtonLightRed: '#FF3939',
  statusButtonGray: '#234D70',
  statusButtonGreen: '#136232',
  statusButtonPurple: '#2B1362',
  statusButtonRed: '#FF3939',
  statusButtonYellow: '#DFA457',

  statusButtonWhiteText: '#FFFFFF',
  statusButtonRedText: '#FFFFFF',
  statusButtonDarkGrayText: '#FFFFFF',
  statusButtonSecondaryGreenText: '#FFFFFF',
  statusButtonPrimaryBlueText: '#FFFFFF',
  statusButtonAlertYellowText: '#FFFFFF',

  // Miscellaneous
  orgSettingsBillingborder: '#202A32',
  counterBadgeText: '#636366',
  counterBadgeActiveText: '#FFFFFF',
  tabText: '#606E7E',
  datepickerText: '#3B3B6D',
  secondaryHover: '#242424',
  primaryDisabledText: '#606E7E',
  iconBackground: '#E8E8E8'
};

declare module '@emotion/react' {
  export interface Theme extends Env0Theme {}
}

export default lightTheme;
