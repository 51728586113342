import React, { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { Section } from 'components/common/section';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { Info, Row as FormRow } from 'components/common/form-components';
import { messagePrefix } from 'components/projects/settings/policies/project-settings-policies';
import { useUpdateEffect } from 'ahooks';
import { WarningIcon as RawWarningIcon } from 'components/common/warning-icon';
import styled from 'types/theme.types';
import {
  useDeploymentForm,
  type PoliciesDeploymentFormSchema
} from 'components/projects/settings/policies/use-policies-form.hook';
import Card, { CardDescription } from 'components/common/card';
import ControlledSaveDiscardButtons from 'components/common/form-controlled/controlled-save-discard-buttons';
import ContinuousDeploymentSettings from 'components/projects/settings/policies/sections/cd-settings';
import Divider from 'components/common/divider';
import useStores from 'hooks/use-stores.hooks';
import type { EnvironmentApi } from '@env0/environment-service/api';
import { useUpdateProjectPolicies } from 'stores/rq/project-policies';

const DisableOutputsPolicyWarning = () => {
  return (
    <WarningContainer data-e2e="project-settings-policies-environment-outputs-warning">
      <WarningIcon />
      <WarningMessage>
        <FormattedMessage id={`${messagePrefix}.environment-outputs.warning`} />
      </WarningMessage>
    </WarningContainer>
  );
};

const CardHeader: React.FC = () => (
  <>
    <FormattedMessage id={`${messagePrefix}.title.deployment`} />
    <CardDescription>
      <FormattedMessage id={`${messagePrefix}.explanation.deployment`} values={setLink(links.docs.POLICIES.ROOT)} />
    </CardDescription>
  </>
);

const DeploymentSettings: React.FC<{ policies: EnvironmentApi.Policy }> = ({ policies }) => {
  const { organizationsStore } = useStores();
  const { mutateAsync: updatePolicies } = useUpdateProjectPolicies();

  const deploymentForm = useDeploymentForm({
    policies,
    onSubmit: async data => {
      await updatePolicies({
        ...data,
        projectId: policies.projectId
      });
    }
  });

  const [showOutputsDisabledWarning, setShowOutputsDisabledWarning] = useState(false);

  const { outputsAsInputsEnabled: isOutputsAsInputsEnabledDirty } = deploymentForm.formState
    .dirtyFields as PoliciesDeploymentFormSchema;
  const outputsAsInputsEnabledValue = deploymentForm.watch('outputsAsInputsEnabled');

  useUpdateEffect(() => {
    setShowOutputsDisabledWarning(isOutputsAsInputsEnabledDirty && outputsAsInputsEnabledValue === false);
  }, [outputsAsInputsEnabledValue, isOutputsAsInputsEnabledDirty]);

  const costEstimationTooltipMessage = useMemo(() => {
    const saasOrShag = organizationsStore.isCurrentOrganizationSelfHosted ? 'shag' : 'saas';
    const messageId = `${messagePrefix}.cost-estimation.tooltip.${saasOrShag}`;
    return <FormattedMessage id={messageId} values={setLink(links.docs.POLICIES.COST_ESTIMATION)} />;
  }, [organizationsStore.isCurrentOrganizationSelfHosted]);

  return (
    <Card title={<CardHeader />}>
      <StyledFlexDiv>
        <StyledFlexItemDiv>
          <Section titleId={`${messagePrefix}.title.deployment.policies`}>
            <ControlledBigCheckbox
              form={deploymentForm}
              name="includeCostEstimation"
              data-e2e="project-settings-policies-cost-estimation-checkbox">
              <FormattedMessage id={`${messagePrefix}.cost-estimation.checkbox`} />
              <Info
                alignTop={false}
                data-e2e="project-settings-policies-cost-estimation-tooltip"
                information={costEstimationTooltipMessage}
              />
            </ControlledBigCheckbox>
            <Row>
              <CheckboxContainer>
                <ControlledBigCheckbox
                  form={deploymentForm}
                  name="outputsAsInputsEnabled"
                  data-e2e="project-settings-policies-environment-outputs-checkbox">
                  <FormattedMessage id={`${messagePrefix}.environment-outputs.checkbox`} />
                  <Info
                    alignTop={false}
                    data-e2e="project-settings-policies-environment-outputs-tooltip"
                    information={
                      <FormattedMessage
                        id={`${messagePrefix}.environment-outputs.tooltip`}
                        values={setLink(links.docs.POLICIES.ENVIRONMENT_OUTPUTS)}
                      />
                    }
                  />
                </ControlledBigCheckbox>
              </CheckboxContainer>
              {showOutputsDisabledWarning && <DisableOutputsPolicyWarning />}
            </Row>
            <ControlledBigCheckbox
              form={deploymentForm}
              name="skipApplyWhenPlanIsEmpty"
              data-e2e="project-settings-policies-skip-apply-when-plan-is-empty-checkbox">
              <FormattedMessage id={`${messagePrefix}.skip-apply-when-plan-is-empty.checkbox`} />
              <Info
                alignTop={false}
                data-e2e="project-settings-policies-skip-apply-when-plan-is-empty-tooltip"
                information={<FormattedMessage id={`${messagePrefix}.skip-apply-when-plan-is-empty.tooltip`} />}
              />
            </ControlledBigCheckbox>
            <ControlledBigCheckbox
              form={deploymentForm}
              name="skipRedundantDeployments"
              data-e2e="project-settings-policies-skip-redundant-deployments-checkbox">
              <FormattedMessage id={`${messagePrefix}.skip-redundant-deployments.checkbox`} />
              <Info
                alignTop={false}
                information={
                  <FormattedMessage
                    id={`${messagePrefix}.skip-redundant-deployments.tooltip`}
                    values={setLink(links.docs.POLICIES.SKIP_REDUNDANT_DEPLOYMENTS)}
                  />
                }
              />
            </ControlledBigCheckbox>
          </Section>
        </StyledFlexItemDiv>
      </StyledFlexDiv>
      <ContinuousDeploymentSettings form={deploymentForm} />
      <Divider orientationMargin="0%" />
      <ControlledSaveDiscardButtons
        saveButtonDataE2E="project-settings-policies-deployment-settings-save"
        cancelButtonDataE2E="project-settings-policies-deployment-settings-discard"
        form={deploymentForm}
        cancelMessageId={'discard.changes'}
        inContainer={true}
      />
    </Card>
  );
};

const StyledFlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
`;

const StyledFlexItemDiv = styled.div`
  flex: 1;
`;

const CheckboxContainer = styled.div`
  width: auto;
`;

const WarningContainer = styled.div`
  margin-left: 1em;
  display: flex;
`;

const WarningIcon = styled(RawWarningIcon)`
  color: ${({ theme }) => theme.warningYellowDarker};
`;

const WarningMessage = styled.div`
  margin-left: 10px;
`;

const Row = styled(FormRow)`
  justify-content: flex-start;
  align-items: center;
`;

export default DeploymentSettings;
