import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { InsightsAnswers, InsightsLiveboards, InsightsToken } from 'types/api.types';

export default (client: HttpClient) => ({
  getToken: (organizationId: string) =>
    client.get<InsightsToken>(`insights/token`, { params: { organizationId } }).then(returnData),

  getAnswers: ({ organizationId }: { organizationId: string }) =>
    client.get<InsightsAnswers>(`insights/answers`, { params: { organizationId } }).then(returnData),

  getLiveboards: ({ organizationId }: { organizationId: string }) =>
    client.get<InsightsLiveboards>(`insights/liveboards`, { params: { organizationId } }).then(returnData),
  getSharedTokenByContext: ({ organizationId, contextName }: { organizationId: string; contextName: string }) =>
    client.get<InsightsToken>(`insights/shared-token`, { params: { organizationId, contextName } }).then(returnData),
  deleteAnswer: ({ organizationId, answerId }: { organizationId: string; answerId: string }) =>
    client.delete(`insights/answers/${answerId}`, { params: { organizationId } }).then(returnData),
  deleteLiveboard: ({ organizationId, liveboardId }: { organizationId: string; liveboardId: string }) =>
    client.delete(`insights/liveboards/${liveboardId}`, { params: { organizationId } }).then(returnData)
});
