import React, { type ReactElement, useCallback, useLayoutEffect, useState, useMemo } from 'react';
import type { TablePaginationConfig, TableColumnProps, TableProps as AntdTableProps } from 'antd';
import { Empty, Table as AntdTable } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from 'components/common/loader';
import styled from 'types/theme.types';
import SearchBar from 'components/common/search-bar';
import { Resizable } from 'react-resizable';
import { Container } from 'components/common/form-components';
import ActionButton from 'components/common/action-button';
import isString from 'lodash/isString';
import { tableGroupBy } from 'utils/table-group-by.utils';
import type { GroupBySettings, GroupedRowType } from 'utils/table-group-by.utils';

export type ColumnProps = TableColumnProps<any>;
type EmptyMessage = {
  emptyComponent?: ReactElement;
  emptyMessageId?: string;
};

export interface FilterableType {
  defaultQuery?: string;
  onFilter: (query: string) => void;
  error?: null | string;
  placeholder?: string;
  disabled?: boolean;
}

export type TableProps<T = any> = AntdTableProps<T> &
  EmptyMessage & {
    searchbarPosition?: 'right' | 'left';
    paginated?: boolean;
    'data-e2e'?: string;
    filterable?: FilterableType;
    showMore?: ShowMoreOptions;
    isLoading?: boolean;
    renderActions?: ReactElement | null;
    groupBySettings?: GroupBySettings<T>;
  };

export type ShowMoreOptions = {
  hasNextPage?: boolean;
  fetchNextPage: () => Promise<unknown>;
};

export const defaultPagination: TablePaginationConfig = {
  position: ['bottomCenter'],
  defaultPageSize: 10,
  hideOnSinglePage: true,
  showSizeChanger: false
};

export const AntDPrebuiltColumns = new Set([
  AntdTable.EXPAND_COLUMN,
  AntdTable.SELECTION_ALL,
  AntdTable.SELECTION_COLUMN,
  AntdTable.SELECTION_INVERT,
  AntdTable.SELECTION_NONE
]);

const ResizableTitle: React.FunctionComponent<TableProps> = ({ onResize, width, ...restProps }: any) => {
  // Was copied from https://ant.design/components/table/#components-table-demo-resizable-column
  // For resizeable columns
  if (!width || Number.isNaN(+width)) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={Number(width)}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}>
      <th {...restProps} />
    </Resizable>
  );
};

const components = { header: { cell: ResizableTitle } };

const Table: React.FunctionComponent<TableProps> = props => {
  const {
    paginated,
    columns,
    emptyMessageId,
    emptyComponent,
    'data-e2e': dataE2e,
    filterable,
    searchbarPosition = 'left',
    showMore = null,
    isLoading,
    renderActions = null,
    groupBySettings = null,
    dataSource,
    ...passedProps
  } = props;

  const [displayedColumns, setDisplayedColumns] = useState(columns || []);

  const handleResize = useCallback<any>(
    (index: number) =>
      (e: any, { size: { width } }: any) => {
        setDisplayedColumns(columns => {
          const newColumns = [...columns];
          newColumns[index].width = width;
          return newColumns;
        });
      },
    []
  );

  const intl = useIntl();

  useLayoutEffect(() => {
    if (columns) {
      setDisplayedColumns(
        columns.map((column, index) => {
          if (AntDPrebuiltColumns.has(column)) {
            return column;
          }

          const fixedColumn = { ...column };

          // only for string titles we should fetch translation. for react components we should not
          if (isString(column.title)) {
            fixedColumn.title = intl.formatMessage({ id: column.title as string });
          }
          fixedColumn.onHeaderCell = (column: any) => ({
            width: column.width,
            onResize: handleResize(index)
          });

          return fixedColumn;
        })
      );
    }
  }, [columns, handleResize, intl]);

  const groupedItems: GroupedRowType<any>[] | undefined = useMemo(() => {
    if (groupBySettings && columns && dataSource) {
      return tableGroupBy(columns, Array.from(dataSource), groupBySettings);
    }
  }, [groupBySettings, columns, dataSource]);

  let rowClassName;
  const locale = emptyComponent
    ? {
        emptyText: emptyComponent
      }
    : emptyMessageId
    ? {
        emptyText: (
          <div data-e2e={`${dataE2e}-empty`}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage id={emptyMessageId} />} />
          </div>
        )
      }
    : {};

  if (passedProps.loading) {
    return <Loader />;
  }

  if (passedProps.onRow) {
    rowClassName = () => 'clickable';
  }

  return (
    <TableContainer data-e2e={dataE2e}>
      <ControlPanelContainer searchbarPosition={searchbarPosition}>
        {filterable ? (
          <SearchBarContainer position={searchbarPosition}>
            <SearchBar
              defaultValue={filterable.defaultQuery}
              disabled={filterable.disabled}
              onFilter={filterable.onFilter}
              placeholder={filterable.placeholder || 'table.filter.default'}
              error={filterable.error}
            />
          </SearchBarContainer>
        ) : null}
        {renderActions ? <div>{renderActions}</div> : null}
      </ControlPanelContainer>
      <ResizeableTable
        loading={isLoading}
        data-e2e="table-component"
        columns={displayedColumns}
        components={{ ...components, ...passedProps.components }}
        rowKey={passedProps.rowKey as any}
        dataSource={groupedItems ?? dataSource}
        {...{ rowClassName, locale: isLoading ? { emptyText: <EmptyMessagePlaceHolder /> } : locale, ...passedProps }}
        pagination={paginated ? { ...defaultPagination, ...passedProps.pagination } : passedProps.pagination || false}
      />
      {showMore?.hasNextPage ? (
        <StyledCenteredContainer>
          <ActionButton action={showMore.fetchNextPage} data-e2e="show-more-button">
            <FormattedMessage id="ellipsis.show.more" />
          </ActionButton>
        </StyledCenteredContainer>
      ) : null}
    </TableContainer>
  );
};

const ControlPanelContainer = styled.div<{ searchbarPosition: TableProps['searchbarPosition'] }>`
  display: flex;
  flex: 1;
  flex-direction: ${props => (props.searchbarPosition === 'left' ? 'row' : 'row-reverse')};
  justify-content: space-between;
  margin-bottom: 1em;
`;

const SearchBarContainer = styled.div<{ position: TableProps['searchbarPosition'] }>`
  margin-${props => (props.position === 'left' ? 'right' : 'left')}: auto;
  width: 35%;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled(AntdTable)`
  overflow: auto;

  .ant-table-column-sort {
    background: ${({ theme }) => theme.primaryWhite};
  }

  th.ant-table-column-sort {
    background: ${({ theme }) => theme.primaryWhite} !important;
  }

  .ant-empty-description {
    color: ${({ theme }) => theme.lightGray};
  }
`;

const ResizeableTable = styled(StyledTable)`
  // Was copied from https://ant.design/components/table/#components-table-demo-resizable-column
  // For resizeable columns
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
`;

const StyledCenteredContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const EmptyMessagePlaceHolder = styled.div`
  min-height: 150px; //our empty message height, to avoid weird jumps when loading and there is no data
`;

export default Table;
