import { type ReactNode, useMemo, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, matchPath, useLocation } from 'react-router-dom';
import compact from 'lodash/compact';
import type { RolesApi } from '@env0/role-service/api';
import { HoverItem, PanelType } from 'components/layout/navbar/navbar-panel.utils';
import { useCloseOnMobile } from 'components/layout/navbar/navbar-panel.hooks';
import { useHasPermissionCallback } from 'hooks/use-has-permission';
import UseIsMobile from 'hooks/use-is-mobile';
import { castPath } from 'utils/router.utils';
import type { Organization } from 'types/api.types';
import { ReactComponent as HomepageIcon } from 'assets/images/org-homepage.svg';
import { ReactComponent as HomepageIconActive } from 'assets/images/org-homepage-active.svg';
import { ReactComponent as ProjectsIcon } from 'assets/images/org-projects.svg';
import { ReactComponent as ProjectsIconActive } from 'assets/images/org-projects-active.svg';
import { ReactComponent as TemplatesIcon } from 'assets/images/templates.svg';
import { ReactComponent as TemplatesIconActive } from 'assets/images/templates-active.svg';
import { ReactComponent as RegistryIcon } from 'assets/images/org-registry.svg';
import { ReactComponent as RegistryIconActive } from 'assets/images/org-registry-active.svg';
import { ReactComponent as VariablesIcon } from 'assets/images/variables.svg';
import { ReactComponent as VariablesIconActive } from 'assets/images/variables-active.svg';
import { ReactComponent as DashboardIcon } from 'assets/images/org-dashboard.svg';
import { ReactComponent as DashboardIconActive } from 'assets/images/org-dashboard-active.svg';
import { ReactComponent as CloudAnalyst } from 'assets/images/cloud-analyst.svg';
import { ReactComponent as CloudAnalystActive } from 'assets/images/cloud-analyst-active.svg';
import { ReactComponent as CloudCompassIcon } from 'assets/images/cloud-compass.svg';
import { ReactComponent as CloudCompassIconActive } from 'assets/images/cloud-compass-active.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';
import { ReactComponent as SettingsIconActive } from 'assets/images/settings-active.svg';
import { RightOutlined } from '@ant-design/icons';
import styled from 'types/theme.types';
import isEmpty from 'lodash/isEmpty';
import { useGetBillingInformation } from 'stores/rq/billing';

interface Props {
  organization: Organization;
  onPanelChange: (panel: PanelType) => void;
}

const OrganizationMenu = ({ organization, onPanelChange }: Props) => {
  const location = useLocation();
  const { isMobile } = UseIsMobile();
  const closeOnMobile = useCloseOnMobile();
  const hasPermission = useHasPermissionCallback();
  const lastProjectLocation = useRef<string>();

  const { isLoading: isLoadingBillingInformation, data: billingData } = useGetBillingInformation();

  useEffect(() => {
    if (location.pathname.includes('/p/')) {
      lastProjectLocation.current = location.pathname;
    }
  }, [location.pathname]);

  const { items, isLoading } = useMemo(() => {
    const insightsActivated = billingData?.insights?.activated;
    const shouldShowAIAnalyst = !!insightsActivated;
    const menuItems: OrgMenuItem[] = [
      {
        key: 'org-menu-homepage',
        exact: true,
        to: () => '/',
        title: <FormattedMessage id="homepage" />,
        icon: <HomepageIcon />,
        activeIcon: <HomepageIconActive />
      },
      {
        key: 'org-menu-projects',
        exact: true,
        to: () => (lastProjectLocation.current ? lastProjectLocation.current : '/projects'),
        onClick: () => onPanelChange(PanelType.Projects),
        title: <FormattedMessage id="projects" />,
        icon: <ProjectsIcon />,
        activeIcon: <ProjectsIconActive />,
        withChevron: true
      },
      {
        key: 'org-menu-templates',
        to: () => '/templates',
        title: <FormattedMessage id="templates" />,
        icon: <TemplatesIcon />,
        activeIcon: <TemplatesIconActive />
      },
      {
        key: 'org-menu-registry',
        to: () => `/registry`,
        title: <FormattedMessage id="registry" />,
        icon: <RegistryIcon />,
        activeIcon: <RegistryIconActive />,
        permissions: ['VIEW_MODULES', 'VIEW_PROVIDERS']
      },
      ...(!isMobile
        ? [
            {
              key: 'org-menu-variables',
              to: () => '/variables',
              title: <FormattedMessage id="variables" />,
              icon: <VariablesIcon />,
              activeIcon: <VariablesIconActive />
            }
          ]
        : []),
      {
        key: 'cloud',
        to: () => `/cloud`,
        title: <FormattedMessage id="cloud" />,
        icon: <CloudCompassIcon />,
        activeIcon: <CloudCompassIconActive />,
        permissions: ['VIEW_DASHBOARD']
      },
      shouldShowAIAnalyst
        ? {
            key: 'org-menu-analytics',
            to: () => `/cloud-analyst`,
            title: <FormattedMessage id="cloud-analyst" />,
            icon: <CloudAnalyst />,
            activeIcon: <CloudAnalystActive />,
            permissions: ['VIEW_DASHBOARD']
          }
        : {
            key: 'org-menu-dashboards',
            to: () => `/dashboards`,
            title: <FormattedMessage id="dashboards" />,
            icon: <DashboardIcon />,
            activeIcon: <DashboardIconActive />,
            permissions: ['VIEW_DASHBOARD']
          },

      {
        key: 'org-menu-settings',
        to: () => `/organizations/${organization.id}`,
        title: <FormattedMessage id="navigation.settings" />,
        icon: <SettingsIcon />,
        activeIcon: <SettingsIconActive />,
        permissions: ['EDIT_ORGANIZATION_SETTINGS']
      }
    ];

    const { authorizedItems, isLoading } = menuItems.reduce<{
      authorizedItems: OrgMenuItem[];
      isLoading: boolean;
    }>(
      (acc, item) => {
        const permissionStatuses = item.permissions?.map(permission => hasPermission(permission)) || [];
        const allPermissionsLoaded = permissionStatuses.every(status => !status.isLoading);
        const isAuthorized = permissionStatuses.some(status => status.isAuthorized);

        if (!allPermissionsLoaded) {
          acc.isLoading = true;
        }

        if ((allPermissionsLoaded && isAuthorized) || isEmpty(item.permissions)) {
          acc.authorizedItems.push(item);
        }

        return acc;
      },
      { authorizedItems: [], isLoading: false }
    );

    return {
      items: compact(authorizedItems),
      isLoading
    };
  }, [billingData, organization.id, isMobile, hasPermission, onPanelChange]);

  if (isLoading || isLoadingBillingInformation) return null;

  return (
    <>
      {items.map(item => {
        const doesNavigate = !!item.to;
        const active = doesNavigate ? Boolean(matchPath(castPath(item.to(), item.exact), location.pathname)) : false;
        const handleClick = () => {
          item.onClick?.();
          closeOnMobile();
        };

        return (
          <Link key={item.key} to={item.to()} onClick={handleClick}>
            <HoverItem active={active} data-e2e={item.key}>
              {hovered => (
                <>
                  {active || hovered ? item.activeIcon : item.icon}
                  <span>{item.title}</span>
                  {item.withChevron && <StyledRightOutlined />}
                </>
              )}
            </HoverItem>
          </Link>
        );
      })}
    </>
  );
};

export default OrganizationMenu;

interface OrgMenuItem {
  key: string;
  to: () => string;
  exact?: boolean;
  onClick?: () => void;
  title: ReactNode;
  icon: ReactNode;
  activeIcon: ReactNode;
  withChevron?: boolean;
  permissions?: RolesApi.RBACPermission[];
}

const StyledRightOutlined = styled(RightOutlined)`
  margin-left: auto;
`;
