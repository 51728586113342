import { ReactComponent as GithubIcon } from 'assets/images/github.svg';
import { ReactComponent as GithubDarkIcon } from 'assets/images/login-github.svg';
import { useTheme } from 'hooks/use-theme';
import { observer } from 'mobx-react';
import type { FC, SVGProps } from 'react';

const GithubLogo: FC<SVGProps<SVGSVGElement>> = props => {
  const { isDarkTheme } = useTheme();
  const IconComponent = isDarkTheme ? GithubDarkIcon : GithubIcon;

  return <IconComponent {...props} />;
};

export default observer(GithubLogo);
