import { type BlueprintApi } from '@env0/blueprint-service/api';
import { type VcsApi } from '@env0/vcs-service/api';
import { Row } from 'antd';
import Button from 'components/common/button';
import Divider from 'components/common/divider';
import ControlledRadioButtons from 'components/common/form-controlled/controlled-radio-buttons';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import { TextInputWithTooltipAndLabel } from 'components/common/input-components/text-input';
import {
  getWebhookTypeByProvider,
  GitOnPremWebhookSection
} from 'components/templates/templates-wizard/vcs/common/auth-section';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useForm, { type UseFormReturnType } from 'hooks/use-form.hook';
import AgentsSelectionControlled from 'components/organizations/agents/agent-selection-controlled';

import {
  bitbucketServerButton,
  githubEnterpriseButton,
  gitlabEnterpriseButton
} from 'components/common/git-providers-radio-buttons';
import { useHasPermission } from 'hooks/use-has-permission';

import React, { useCallback, useMemo } from 'react';
import type { UseFormMethods } from 'react-hook-form/dist/types/form';
import { FormattedMessage } from 'react-intl';
import { useUpdateVcsConnection, useCreateVcsConnection } from 'stores/rq/vcs-connections';
import { useGetWebhookSecret } from 'stores/rq/webhook-secret';
import styled from 'types/theme.types';
import * as Yup from 'yup';
import { type SchemaOf } from 'yup';
import Modal from 'components/common/modal';
import { DEFAULT_AGENT_ID } from 'constants/agents.constants';
import type { GitHostedProviderTypes } from 'types/api.types';

export type VcsConnectionsModalProps = {
  id?: string;
  hideModal: () => void;
  showModal?: () => void;
  isModalOpen: boolean;
  vcsConnection?: VcsApi.VcsConnection;
  vcsType?: VcsApi.VcsType;
  onSuccess?: () => void;
};

export const VcsConnectionsModal = ({
  isModalOpen,
  hideModal,
  vcsConnection,
  vcsType,
  onSuccess
}: VcsConnectionsModalProps) => {
  const { mutateAsync: updateVcsConnection } = useUpdateVcsConnection();
  const { mutateAsync: createVcsConnection } = useCreateVcsConnection();
  const organizationId = useCurrentOrganizationId();

  const saveVcsConnection = useCallback(
    async (data: VcsApi.VcsConnectionPayload, form: UseFormMethods<VcsApi.VcsConnectionPayload>) => {
      if (vcsConnection?.id) {
        const changes = form.getValues(Object.keys(form.formState.dirtyFields) as any);
        await updateVcsConnection({ id: vcsConnection.id, data: changes });
      } else {
        await createVcsConnection(data);
      }

      onSuccess?.();
      hideModal();
    },
    [createVcsConnection, updateVcsConnection, hideModal, onSuccess, vcsConnection]
  );

  const form = useForm<VcsApi.VcsConnectionPayload>({
    initialValues: {
      type: vcsType ?? 'GitHubEnterprise',
      organizationId,
      ...vcsConnection
    },
    schema: vcsConnectionModalFormSchema,
    shouldUnregister: false,
    onSubmit: saveVcsConnection
  });

  const modalFooter = (
    <>
      <Button data-e2e="cancel-vcs-connection-button" onClick={() => hideModal()} disabled={form.isSubmitting}>
        <FormattedMessage id="organization.settings.agents.vcs-connections-modal.cancel-button" />
      </Button>
      <Button
        data-e2e="save-vcs-connection-button"
        isLoading={form.isSubmitting}
        type="primary"
        onClick={form.submit}
        disabled={!form.canSave || form.isSubmitting}>
        <FormattedMessage id="organization.settings.agents.vcs-connections-modal.save-button" />
      </Button>
    </>
  );

  return (
    <Modal
      data-e2e={'provider-modal'}
      open={isModalOpen}
      onCancel={hideModal}
      width="700px"
      titleId={`organization.settings.agents.vcs-connections-modal.${vcsConnection?.id ? 'edit' : 'create'}-title`}
      footer={modalFooter}
      destroyOnClose={true}
      centered>
      <VcsConnectionsModalContent vcsConnection={vcsConnection} vcsType={vcsType} form={form} />
    </Modal>
  );
};

export const vcsConnectionModalFormSchema: SchemaOf<VcsApi.VcsConnectionPayload> = Yup.object({
  organizationId: Yup.string().required(),
  name: Yup.string().required('validation.required').default(''),
  url: Yup.string().required('validation.required').default(''),
  type: Yup.mixed<VcsApi.VcsType>()
    .oneOf(['GitHubEnterprise', 'GitLabEnterprise', 'BitBucketServer'])
    .required('validation.required')
    .default('GitHubEnterprise'),
  vcsAgentKey: Yup.string().default(DEFAULT_AGENT_ID),
  connectionType: Yup.mixed<VcsApi.ConnectionType>().oneOf(['DeploymentPipeline', 'CodeGeneration']),
  authorizationId: Yup.string()
});

const buttons = [githubEnterpriseButton, gitlabEnterpriseButton, bitbucketServerButton];

const VcsConnectionsModalContent = ({
  vcsConnection,
  vcsType,
  form
}: {
  vcsConnection?: VcsApi.VcsConnection;
  vcsType?: string;
  form: UseFormReturnType<VcsApi.VcsConnectionPayload>;
}) => {
  const type = form.watch('type');
  const webhookType = getWebhookTypeByProvider(type as GitHostedProviderTypes);
  const { data: webhookSecretResponse } = useGetWebhookSecret(webhookType);

  const { isAuthorized: hasPermissionToOrgSettings } = useHasPermission('EDIT_ORGANIZATION_SETTINGS');

  const fixedType = vcsType ?? vcsConnection?.type;

  const providerButtons = useMemo(
    () =>
      buttons.map(button => {
        return { ...button, disabled: Boolean(fixedType && fixedType !== button.value) };
      }),
    [fixedType]
  );

  return (
    <>
      <Row align="middle" justify="center">
        <ProviderRadioButtons
          buttons={providerButtons}
          form={form}
          name={'type'}
          size={'huge'}
          disabled={form.readonly}
        />
      </Row>
      <Row align="middle" justify="start">
        <ControlledTextInput
          inline
          width={100}
          mandatory
          label={{ id: `organization.settings.agents.vcs-connections-modal.connection-name` }}
          name="name"
          form={form}
          data-e2e="create-vcs-connection-name"
          autoFocus
        />
      </Row>

      <Row align="middle" justify="start">
        <StyledRow>
          {!hasPermissionToOrgSettings ? (
            <TextInputWithTooltipAndLabel
              tooltip={{ titleId: 'organization.settings.agents.vcs-connections-modal.agent-key-disabled' }}
              value={`Default`}
              disabled={true}
              label={{ id: 'organization.settings.agents.vcs-connections-modal.agent-key' }}
              data-e2e={'vcs-connection-agent-selection-disabled'}
            />
          ) : (
            <AgentsSelectionControlled
              form={form}
              css={{ width: '100%' }}
              label={{ id: 'organization.settings.agents.vcs-connections-modal.agent-key' }}
              name={'vcsAgentKey'}
              data-e2e={`controlled-select-${vcsConnection?.name ?? 'new-vcs-connection'}`}
              shouldDisplaySaasAgent={false}
            />
          )}
        </StyledRow>
      </Row>

      <Row align="middle" justify="start">
        <ControlledTextInput
          inline
          width={100}
          mandatory
          label={{ id: `organization.settings.agents.vcs-connections-modal.vcs-url` }}
          info={{ id: `organization.settings.agents.vcs-connections-modal.vcs-url.info` }}
          name="url"
          form={form}
          data-e2e="create-vcs-connection-url"
          autoFocus
          disabled={vcsConnection?.url}
        />
      </Row>
      <Row align="middle" justify="start">
        <Divider data-e2e="authentication-divider" titleId="templates.add.new.divider.authentication" />
        <GitOnPremWebhookSection
          gitProvider={type as BlueprintApi.SourceTypes}
          webhookSecret={webhookSecretResponse?.webhookSecret ?? ''}
          isTwoRows={true}
        />
      </Row>
    </>
  );
};

const ProviderRadioButtons = styled(ControlledRadioButtons)`
  flex-basis: 1000px;
  margin-bottom: 20px;
`;

const StyledRow = styled.div`
  width: 100%;
`;
