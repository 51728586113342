import type { Env0Theme } from 'constants/themes.constants';
import { stage } from 'constants/config';
const baseUrl = stage === 'prod' ? 'https://app.env0.com' : `https://${stage}.dev.env0.com`;

/**
 * Creates a mapping of ThoughtSpot CSS variables to Env0Theme values
 */
export const getThoughtspotCustomizations = (theme: Env0Theme) => {
  const variables = {
    // Root and application-wide variables
    '--ts-var-root-color': theme.textColor,
    '--ts-var-root-background': theme.primaryGray,
    '--ts-var-root-font-family': "'ProximaNova', sans-serif",
    '--ts-var-root-text-transform': 'none',
    '--ts-var-application-color': theme.textGray,
    '--ts-var-root-secondary-color': theme.textGray,

    // Nav panel
    '--ts-var-nav-color': theme.textBaseGray,
    '--ts-var-nav-background': theme.backgroundGray,

    // Buttons
    '--ts-var-button-border-radius': theme.defaultBorderRadius,
    '--ts-var-button--icon-border-radius': theme.defaultBorderRadius,

    // Primary buttons
    '--ts-var-button--primary-color': theme.whiteColor,
    '--ts-var-button--primary-background': theme.primaryGreen,
    '--ts-var-button--primary--hover-background': theme.secondaryGreen,
    '--ts-var-button--primary--font-family': 'inherit',
    '--ts-var-button--primary--active-background': theme.secondaryGreen,

    // Secondary buttons
    '--ts-var-button--secondary-color': theme.textGray,
    '--ts-var-button--secondary-background': theme.backgroundGray,
    '--ts-var-button--secondary--hover-background': theme.secondaryHover,
    '--ts-var-button--secondary--font-family': 'inherit',
    '--ts-var-button--secondary--active-background': theme.lightGray,

    // Tertiary buttons
    '--ts-var-button--tertiary-color': theme.textGray,
    '--ts-var-button--tertiary-background': 'transparent',
    '--ts-var-button--tertiary--hover-background': theme.secondaryHover,
    '--ts-var-button--tertiary--active-background': theme.lightGray,

    // Checkboxes
    '--ts-var-checkbox-error-border': theme.errorRed,
    '--ts-var-checkbox-border-color': theme.lightGray,
    '--ts-var-checkbox-hover-border': theme.primaryBlue,
    '--ts-var-checkbox-active-color': theme.primaryBlue,
    '--ts-var-checkbox-checked-color': theme.primaryBlue,
    '--ts-var-checkbox-checked-disabled': theme.primaryDisabledGray,
    '--ts-var-checkbox-highlighted-hover-color': theme.secondaryHover,
    '--ts-var-checkbox-background-color': theme.primaryWhite,

    // Menu components
    '--ts-var-menu-color': theme.textGray,
    '--ts-var-menu-background': theme.primaryWhite,
    '--ts-var-menu-font-family': 'inherit',
    '--ts-var-menu-text-transform': 'none',
    '--ts-var-menu--hover-background': theme.secondaryHover,
    '--ts-var-menu-seperator-background': theme.separatorGray,
    '--ts-var-menu-selected-text-color': theme.primaryBlue,

    // Dialogs and modals
    '--ts-var-dialog-body-background': theme.primaryWhite,
    '--ts-var-dialog-body-color': theme.textGray,
    '--ts-var-dialog-header-background': theme.primaryWhite,
    '--ts-var-dialog-header-color': theme.textGray,
    '--ts-var-dialog-footer-background': theme.primaryWhite,

    // Segment control
    '--ts-var-segment-control-hover-background': theme.secondaryHover,

    // Lists
    '--ts-var-list-selected-background': theme.lightBlue,
    '--ts-var-list-hover-background': theme.secondaryHover,

    // Liveboard
    '--ts-var-liveboard-edit-bar-background': theme.backgroundGray,
    '--ts-var-liveboard-cross-filter-layout-background': theme.primaryWhite,

    // Visualizations and Answers
    '--ts-var-viz-title-color': theme.textGray,
    '--ts-var-viz-title-font-family': 'inherit',
    '--ts-var-viz-title-text-transform': 'none',
    '--ts-var-viz-description-color': theme.dividerText,
    '--ts-var-viz-description-font-family': 'inherit',
    '--ts-var-viz-description-text-transform': 'none',
    '--ts-var-viz-border-radius': theme.defaultBorderRadius,
    '--ts-var-viz-box-shadow': theme.defaultShadow,
    '--ts-var-viz-background': theme.primaryWhite,
    '--ts-var-viz-legend-hover-background': theme.secondaryHover,

    // Filter chips
    '--ts-var-chip-border-radius': theme.defaultBorderRadius,
    '--ts-var-chip-title-font-family': 'inherit',
    '--ts-var-chip-box-shadow': theme.defaultShadow,
    '--ts-var-chip-background': theme.primaryGray,
    '--ts-var-chip-color': theme.textBaseGray,
    '--ts-var-chip--hover-background': theme.sidebarHoverGray,
    '--ts-var-chip--hover-color': theme.textBaseGray,

    // Axis titles and labels
    '--ts-var-axis-title-color': theme.textGray,
    '--ts-var-axis-title-font-family': 'inherit',
    '--ts-var-axis-data-label-color': theme.textGray,
    '--ts-var-axis-data-label-font-family': 'inherit',

    // Chart selection widget
    '--ts-var-answer-chart-select-background': theme.backgroundGray,
    '--ts-var-answer-chart-hover-background': theme.secondaryHover,

    // Chart switcher
    '--ts-var-answer-view-table-chart-switcher-active-background': theme.lightBlue,
    '--ts-var-answer-edit-panel-background-color': theme.backgroundGray,

    // Spotter interface
    '--ts-var-spotter-input-background': theme.primaryWhite,
    '--ts-var-spotter-prompt-background': theme.backgroundGray,

    // Search bar and auto-suggestion panels
    '--ts-var-search-data-button-font-color': theme.textGray,
    '--ts-var-search-data-button-background': theme.backgroundGray,
    '--ts-var-search-data-button-font-family': 'inherit',
    '--ts-var-search-bar-text-font-color': theme.textGray,
    '--ts-var-search-bar-text-font-family': 'inherit',
    '--ts-var-search-bar-text-font-style': 'normal',
    '--ts-var-search-bar-background': theme.primaryWhite,
    '--ts-var-search-auto-complete-background': theme.primaryWhite,
    '--ts-var-search-auto-complete-font-color': theme.textGray,
    '--ts-var-search-auto-complete-subtext-font-color': theme.dividerText,
    '--ts-var-search-navigation-button-background': theme.backgroundGray,
    '--ts-var-search-bar-navigation-help-text-background': theme.backgroundGray,
    '--ts-var-search-bar-auto-complete-hover-background': theme.secondaryHover,

    // Data and edit panels
    '--ts-var-answer-data-panel-background-color': theme.backgroundGray,
    '--ts-var-answer-view-table-chart-switcher-background': theme.backgroundGray,

    // Modular Homepage
    '--ts-var-home-watchlist-selected-text-color': theme.primaryBlue,
    '--ts-var-home-card-color': theme.textGray,
    '--ts-var-home-favorite-suggestion-card-text-color': theme.textGray,
    '--ts-var-home-favorite-suggestion-card-text-font-color': theme.textGray,
    '--ts-var-home-favorite-suggestion-card-background': theme.primaryWhite,
    '--ts-var-home-favorite-suggestion-card-icon-color': theme.primaryBlue,

    // Natural Language Search panel
    '--ts-var-sage-bar-header-background-color': theme.backgroundGray,
    '--ts-var-source-selector-background-color': theme.backgroundGray,
    '--ts-var-sage-search-box-font-color': theme.textGray,
    '--ts-var-sage-search-box-background-color': theme.primaryWhite,
    '--ts-var-sage-embed-background-color': theme.primaryWhite,
    '--ts-var-sage-seed-questions-background': theme.lightBlue,
    '--ts-var-sage-seed-questions-font-color': theme.textGray,
    '--ts-var-sage-seed-questions-hover-background': theme.secondaryHover,
    '--ts-var-source-selector-hover-color': theme.secondaryHover
  };

  const rules_UNSTABLE = {
    '.chat-input-module__chatInputWrapper': {
      // spotter input color
      background: theme.primaryWhite,
      color: theme.textColor
    },

    '[data-testid="conv-assist-chat-input"]': {
      color: `${theme.textColor} !important`
    },
    '[data-testid="sage-error-bar"]': {
      // error alert in answer
      'background-color': `${theme.lightRed} !important`,
      color: theme.primaryWhite
    },
    '[data-testid="error-message"]': {
      // error alert in spotter
      'background-color': `${theme.lightRed} !important`,
      color: theme.primaryWhite
    },

    '[data-radiant-v2].icon-module__black': {
      // spotter model icon
      fill: `${theme.textColor} !important`
    },
    '.label-v2 > p': {
      // legend text color
      color: `${theme.textColor} !important`
    },
    '.pinboard-edit-header-module__headerLogo': {
      // hide ts logo in liveboard edit
      visibility: 'hidden'
    },
    '[data-testid="action-selector-nav-btn"]': {
      // remove Custom Actions button
      display: 'none'
    }
  };
  const strings = {
    Liveboard: 'Dashboard',
    liveboard: 'dashboard',
    Liveboards: 'Dashboards',
    liveboards: 'dashboards',
    Answer: 'Insight',
    answer: 'insight',
    Answers: 'Insights',
    answers: 'insights',
    Spotter: 'Milo',
    'insight questions': 'answer questions'
  };
  const iconSpriteUrl = `${baseUrl}/milo-head.svg`;
  return {
    iconSpriteUrl,
    content: {
      strings
    },
    style: { customCSS: { variables, rules_UNSTABLE } }
  };
};
