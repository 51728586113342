import useApiClient from 'hooks/use-api-client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { useGetBillingInformation } from 'stores/rq/billing';
import { useHasPermission } from 'hooks/use-has-permission';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';

const useEnabledInsights = () => {
  const { isLoading: isLoadingBillingInformation, data: billingData } = useGetBillingInformation();
  const { isAuthorized, isLoading: isLoadingPermission } = useHasPermission(['VIEW_DASHBOARD']);

  return !!(!isLoadingPermission && isAuthorized && !isLoadingBillingInformation && billingData?.insights?.activated);
};

export const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'insights'] as const;
  const cacheKeys = {
    answers: [...baseKey, 'answers'] as const,
    liveboards: [...baseKey, 'liveboards'] as const,
    token: [...baseKey, 'token'] as const
  } as const;

  return cacheKeys;
};
export const useGetInsightsAnswers = ({
  enabled = true
}: {
  enabled?: boolean;
} = {}) => {
  const apiClient = useApiClient();
  const { answers } = useCacheKeys();

  return useQuery({
    queryKey: answers,
    enabled,
    queryFn: async ({ queryKey: [organizationId] }) => apiClient.insights.getAnswers({ organizationId })
  });
};

export const useGetInsightsLiveboards = ({
  enabled = true
}: {
  enabled?: boolean;
} = {}) => {
  const apiClient = useApiClient();
  const { liveboards } = useCacheKeys();

  return useQuery({
    queryKey: liveboards,
    enabled,
    queryFn: ({ queryKey: [organizationId] }) => apiClient.insights.getLiveboards({ organizationId })
  });
};

export const useGetAnswersMapIdToName = () => {
  const enabled = useEnabledInsights();

  const { data: answers } = useGetInsightsAnswers({ enabled });
  return answers?.reduce((acc, answer) => ({ ...acc, [answer.id]: { name: answer.name } }), {}) ?? {};
};

export const useGetLiveboardsMapIdToName = () => {
  const enabled = useEnabledInsights();

  const { data: liveboards } = useGetInsightsLiveboards({ enabled });
  return liveboards?.reduce((acc, liveboard) => ({ ...acc, [liveboard.id]: { name: liveboard.name } }), {}) ?? {};
};

export const useDeleteAnswer = () => {
  const apiClient = useApiClient();
  const organizationId = useCurrentOrganizationId();
  const cacheKeys = useCacheKeys();

  return useMutation({
    mutationKey: cacheKeys.answers,
    mutationFn: async (answerId: string) => {
      return apiClient.insights.deleteAnswer({ answerId, organizationId });
    },
    onSuccess: () => {
      getQueryClientInstance().invalidateQueries({ queryKey: cacheKeys.answers });
    }
  });
};

export const useDeleteLiveboard = () => {
  const apiClient = useApiClient();
  const organizationId = useCurrentOrganizationId();
  const cacheKeys = useCacheKeys();

  return useMutation({
    mutationKey: cacheKeys.liveboards,
    mutationFn: async (liveboardId: string) => {
      return apiClient.insights.deleteLiveboard({ liveboardId, organizationId });
    },
    onSuccess: () => {
      getQueryClientInstance().invalidateQueries({ queryKey: cacheKeys.liveboards });
    }
  });
};
