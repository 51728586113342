import BaseService from 'services/base-service';
import { AuthType, init, logout } from '@thoughtspot/visual-embed-sdk';
import { thoughtspotApiUrl } from 'constants/config';
import type { InsightsApi } from '@env0/insights-service/api';
import type { Env0Theme } from 'constants/themes.constants';
import { getThoughtspotCustomizations } from 'constants/thoughtspot-theme-mapping';

export type ThoughtspotFeature = InsightsApi.InsightsContextName | 'insights';

export class ThoughtspotService extends BaseService {
  private logoutPromise: ReturnType<typeof logout> | null = null;
  private initializing: string | null = null;
  private initialized: string | null = null;
  userId: string | null = null;

  init = async (theme: Env0Theme, organizationId: string, feature: ThoughtspotFeature) => {
    const customizations = getThoughtspotCustomizations(theme);
    const wantToInitialize = `${theme.mode}-${organizationId}-${feature}`;

    if (this.initialized === wantToInitialize) return;

    // this is a failsafe to prevent multiple init calls from happening at the same time
    // this can happen if the user switches pages quickly
    if (this.initializing && this.initializing !== wantToInitialize) {
      throw new Error('Already initializing for another organization or feature');
    }

    this.initializing = wantToInitialize;

    if (this.initialized) {
      await this.logout();
    }

    init({
      callPrefetch: true,
      authType: AuthType.TrustedAuthTokenCookieless,
      thoughtSpotHost: thoughtspotApiUrl,
      autoLogin: true,
      getAuthToken: async () => {
        switch (feature) {
          case 'insights':
            return this.service.apiClient.insights.getToken(organizationId!).then(response => {
              this.userId = response.userId;
              return response.token;
            });
          case 'moduleUsage':
            return this.service.apiClient.insights
              .getSharedTokenByContext({ organizationId: organizationId!, contextName: feature })
              .then(response => response.token);
          default:
            throw new Error('Feature not supported');
        }
      },
      customizations
    });

    this.initialized = wantToInitialize;
    this.initializing = null;
  };

  logout = async () => {
    if (!this.logoutPromise) {
      this.logoutPromise = logout();
    }

    await this.logoutPromise;

    this.logoutPromise = null;
    this.initialized = null;
  };
}
