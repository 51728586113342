import CloudOutlined from '@ant-design/icons/lib/icons/CloudOutlined';
import { SelectOption } from 'components/common/input-components/select';
import React, { useMemo } from 'react';
import Select from 'components/common/input-components/select';
import { useGetAgents } from 'stores/rq/agents';
import styled from 'types/theme.types';
import { DEFAULT_AGENT_ID } from 'constants/agents.constants';

export type AgentsSelectionType = {
  shouldDisplaySaasAgent?: boolean;
  shouldDisplayDefaultAgent?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  'data-e2e': string;
  disabled?: boolean;
};

export interface AgentDetails {
  displayName?: string;
  agentKey: string;
  isSaas?: boolean;
}

const DEFAULT_AGENT_PLACEHOLDER = { agentKey: DEFAULT_AGENT_ID, displayName: `Default Agent` };

const AgentsSelection: React.FC<AgentsSelectionType> = ({
  shouldDisplaySaasAgent = true,
  shouldDisplayDefaultAgent = true,
  onChange,
  value,
  'data-e2e': dataE2e,
  disabled
}) => {
  const { data: agents } = useGetAgents(true);

  const agentsToSelect: AgentDetails[] = useMemo(() => {
    if (!agents) return [DEFAULT_AGENT_PLACEHOLDER];

    const defaultAgent = agents.find(agent => agent.isDefault);

    return [
      ...(shouldDisplayDefaultAgent
        ? [{ agentKey: DEFAULT_AGENT_ID, displayName: `Default - ${defaultAgent?.agentKey}` }]
        : []),
      ...(shouldDisplaySaasAgent ? agents : agents.filter(agent => !agent.isSaas))
    ];
  }, [agents, shouldDisplayDefaultAgent, shouldDisplaySaasAgent]);

  return (
    <Select data-e2e={dataE2e} value={value} onChange={onChange} disabled={disabled}>
      {agentsToSelect.map((agent: AgentDetails) => (
        <SelectOption key={agent.agentKey} value={agent.agentKey} data-e2e={`agent-option-${agent.agentKey}`}>
          <StyledOption key={agent.agentKey}>
            {agent.isSaas && (
              <>
                <CloudOutlined data-e2e="saas-agent-mark" /> &nbsp;
              </>
            )}
            {agent.displayName ?? agent.agentKey}
          </StyledOption>
        </SelectOption>
      ))}
    </Select>
  );
};

const StyledOption = styled.div`
  display: flex;
  align-items: center;
`;

export default AgentsSelection;
