import React, { type ReactNode, useMemo } from 'react';
import { App, ConfigProvider } from 'antd';
import { useIntl } from 'react-intl';
import type { Locale } from 'antd/lib/locale';
import antdLocaleUS from 'antd/lib/locale/en_US';
import { getAntdTheme } from 'constants/antd.constants';
import { observer } from 'mobx-react';
import { useTheme } from 'hooks/use-theme';
import { css, Global } from '@emotion/react';
import type { Env0Theme } from 'constants/themes.constants';

/**
 * This component is used to wrap the entire application.
 * The ConfigProvider sets the base tokens and the locales (translations) for the antd components.
 * The App component is used to set the base styles for the application (i.e. root font-size, root color, etc.)
 */

const AntdWrapper: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const intl = useIntl();
  const { appTheme } = useTheme();

  const theme = useMemo(() => getAntdTheme(appTheme), [appTheme]);

  const locale = useMemo<Locale>(
    () => ({
      ...antdLocaleUS,
      Table: {
        ...antdLocaleUS.Table,
        triggerAsc: intl.formatMessage({ id: 'sort.ascending' }),
        triggerDesc: intl.formatMessage({ id: 'sort.descending' }),
        cancelSort: intl.formatMessage({ id: 'sort.cancel' })
      }
    }),
    [intl]
  );
  return (
    <ConfigProvider theme={theme} locale={locale}>
      <Global styles={getGlobalStyles(appTheme)} />
      <App>{children}</App>
    </ConfigProvider>
  );
};

export default observer(AntdWrapper);

export const getGlobalStyles = (theme: Env0Theme) => css`
  * {
    scrollbar-width: thin;
    scrollbar-color: ${theme.scrollbarThumbBackground} ${theme.scrollbarTrackBackground};
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${theme.scrollbarThumbBackground};
    border-radius: 12px;
  }

  *::-webkit-scrollbar-track {
    background-color: ${theme.scrollbarTrackBackground};
  }
`;
