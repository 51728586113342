import React from 'react';
import { MoonFilled, SunFilled } from '@ant-design/icons';
import Tooltip from 'components/common/tooltip';
import { Button } from 'antd';
import { useTheme } from 'hooks/use-theme';

export default function AppThemeSwitchButton() {
  const { isDarkTheme, setAppTheme } = useTheme();

  return (
    <Tooltip placement="left" titleId="toggle.theme">
      <Button type="text" onClick={() => setAppTheme(isDarkTheme ? 'light' : 'dark')}>
        {isDarkTheme ? <MoonFilled /> : <SunFilled />}
      </Button>
    </Tooltip>
  );
}
