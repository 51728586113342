import lightTheme, { darkTheme } from 'constants/themes.constants';
import useStores from './use-stores.hooks';

export function useTheme() {
  const {
    userStore: { appTheme, isDarkTheme, setAppTheme }
  } = useStores();

  const theme = appTheme === 'light' ? lightTheme : darkTheme;

  return { appTheme: theme, isDarkTheme, setAppTheme };
}
