import SettingsIcon from 'assets/images/settings-icon.svg';
import AgentsIcon from 'assets/images/agent-icon.svg';
import ApiKeyIcon from 'assets/images/api-key-icon.svg';
import CredentialsIcon from 'assets/images/credentials-icon.svg';
import NotificationsIcon from 'assets/images/notification-icon.svg';
import PoliciesIcon from 'assets/images/policies-icon.svg';
import KeysIcon from 'assets/images/key-icon.svg';
import BillingIcon from 'assets/images/billing-icon.svg';
import IntegrationsIcon from 'assets/images/integrations-icon.svg';
import TeamsIcon from 'assets/images/team-icon.svg';
import RolesIcon from 'assets/images/roles-icon.svg';
import UsersIcon from 'assets/images/user-icon.svg';
import AuditLogsIcon from 'assets/images/audit-logs.svg';
import VcsIcon from 'assets/images/git-self-hosted.svg';

import { useNavigate } from 'react-router-dom';
import type { Action } from 'kbar';
import type { OrganizationSettingsTabName } from 'constants/organization-settings.constants';
import { ORGANIZATION_SETTINGS_TABS } from 'constants/organization-settings.constants';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHasPermission } from 'hooks/use-has-permission';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';

export const ORGANIZATION_SETTINGS_ROOT_ID = 'SETTINGS_SECTION';

const section = 'Organization Settings';

const tabToIcon: Record<OrganizationSettingsTabName, string> = {
  general: SettingsIcon,
  users: UsersIcon,
  teams: TeamsIcon,
  roles: RolesIcon,
  'api-keys': ApiKeyIcon,
  credentials: CredentialsIcon,
  keys: KeysIcon,
  policy: PoliciesIcon,
  'audit-logs': AuditLogsIcon,
  notifications: NotificationsIcon,
  agents: AgentsIcon,
  integrations: IntegrationsIcon,
  billing: BillingIcon,
  vcs: VcsIcon
};

const useOrganizationSettingsTabsActions = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { isAuthorized } = useHasPermission('EDIT_ORGANIZATION_SETTINGS');
  const currentOrganizationId = useCurrentOrganizationId();

  const tabActions: Action[] = useMemo(() => {
    if (!isAuthorized) {
      return [];
    }

    return ORGANIZATION_SETTINGS_TABS.map(tabName => ({
      id: `SETTINGS_TAB_${tabName}`,
      parent: ORGANIZATION_SETTINGS_ROOT_ID,
      name: intl.formatMessage({ id: `organization.settings.menu.${tabName}` }),
      section,
      perform: () => navigate(`/organizations/${currentOrganizationId}/${tabName}`),
      icon: tabToIcon[tabName]
    }));
  }, [isAuthorized, intl, navigate, currentOrganizationId]);

  return tabActions;
};

export default useOrganizationSettingsTabsActions;
