import React, { type ReactNode } from 'react';
import { observer } from 'mobx-react';
import { ThemeProvider } from '@emotion/react';
import { useTheme } from 'hooks/use-theme';

const ThemeProviderWrapper: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { appTheme } = useTheme();

  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};

export default observer(ThemeProviderWrapper);
