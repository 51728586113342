import React from 'react';
import type { ColumnProps } from 'components/common/table';
import { compareTeamsByDescription, compareTeamsByName, compareTeamsByUsersNumber } from 'utils/teams.utils';
import { UserAvatarGroup } from 'components/common/user-avatar';
import type { Role, User } from 'types/api.types';
import type { TeamRoleAssignmentRow } from 'components/common/role-assignments/types';
import RoleAssignmentCardRoleSelect from 'components/common/role-assignments/role-assignment-card-role-select';
import RoleAssignmentCardAssignCheckbox from 'components/common/role-assignments/role-assignment-card-assign-checkbox';
import { ProjectRoleAssignmentHeader } from 'components/common/role-assignments/project-role-assignment-header';
import Checkbox from 'components/common/input-components/checkbox';
import type { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { FormattedMessage } from 'react-intl';

const FilterAssignedOnly = ({ onChange }: { onChange?: (value: CheckboxChangeEvent) => void }) => {
  return (
    <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
      <Checkbox data-e2e="show-only-assigned" onChange={onChange}>
        <FormattedMessage id="teams.assigned.showOnly" />
      </Checkbox>
    </div>
  );
};

export const buildTeamRoleAssignmentsColumns = ({
  scope,
  onChangeIsAssigned,
  onChangeAssignmentRole,
  onChangeFilterAssigned,
  defaultRoles
}: {
  scope: 'organization' | 'project' | 'environment';
  onChangeAssignmentRole: (teamId: string, role: string) => void;
  onChangeIsAssigned: (teamId: string, assigned: boolean) => void;
  onChangeFilterAssigned?: (value: boolean) => void;
  defaultRoles: Role[];
}): ColumnProps[] => [
  {
    title: 'assign',
    dataIndex: 'assigned',
    width: '7%',
    filterDropdown: onChangeFilterAssigned && (
      <FilterAssignedOnly onChange={e => onChangeFilterAssigned?.(e.target.checked)} />
    ),
    render: (_, record: TeamRoleAssignmentRow) => {
      return (
        <RoleAssignmentCardAssignCheckbox scopeName={scope} record={record} onChangeIsAssigned={onChangeIsAssigned} />
      );
    }
  },
  {
    title: 'teams.name',
    dataIndex: ['team', 'name'],
    width: '18%',
    ellipsis: true,
    sorter: (team1, team2) => compareTeamsByName(team1.team, team2.team)
  },
  {
    title: 'description',
    dataIndex: ['team', 'description'],
    width: '36%',
    ellipsis: {
      showTitle: false
    },
    sorter: (team1, team2) => compareTeamsByDescription(team1.team, team2.team)
  },
  {
    title: 'members',
    dataIndex: ['team', 'users'],
    render: (users: User[]) => (
      <div data-e2e="avatar-group">
        <UserAvatarGroup users={users} maxCount={3} />
      </div>
    ),
    sorter: (team1, team2) => compareTeamsByUsersNumber(team1.team, team2.team)
  },
  {
    title: scope === 'project' ? <ProjectRoleAssignmentHeader /> : 'role',
    dataIndex: 'role',
    render: (_, record: TeamRoleAssignmentRow) => {
      return (
        <RoleAssignmentCardRoleSelect
          scopeName={scope}
          record={record}
          onChangeAssignmentRole={onChangeAssignmentRole}
          defaultRoles={defaultRoles}
          tab={'teams'}
        />
      );
    }
  }
];
