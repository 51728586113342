import get from 'lodash/fp/get';
import keyBy from 'lodash/fp/keyBy';
import type { IObservableArray } from 'mobx';
import { action, computed, observable } from 'mobx';
import BrowserStorage from 'services/BrowserStorage';
import type { OrganizationApi } from '@env0/organization-service/api';
import history from 'services/history-service';

import type { ApiKey, NewOrganization, Organization, OrganizationInvitation, Organizations } from 'types/api.types';
import type ServiceContainer from 'services/service-container';
import BaseService from 'services/base-service';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import { EventNames, track } from 'utils/analytics.utils';
import type { RolesApi } from '@env0/role-service/api';

const INVITE_STORAGE_KEY = 'organizationInvitation';
export const LAST_VISITED_ORGANIZATION_AND_USER_ID_STORAGE_KEY = 'lastVisitedOrganizationAndUserId';
const DEFAULT_NAVIGATE_PATH_TO_TEMPLATES = '/templates';

export const getCurrentUserAndOrgId = () => {
  const { userId, organizationId } = BrowserStorage.getItem(LAST_VISITED_ORGANIZATION_AND_USER_ID_STORAGE_KEY);

  return { userId, organizationId };
};

export class OrganizationsStore extends BaseService {
  @observable currentOrganizationId: string | null = null;
  @observable organizations: Organizations = {};
  @observable organizationInvitation: OrganizationInvitation | null = null;
  @observable invitationError = '';
  @observable apiKeys: IObservableArray<ApiKey> = observable([]);
  @observable isDoneFetchingOrganizations: boolean = false;

  constructor(service: ServiceContainer) {
    super(service);
    const invitation = BrowserStorage.getItem(INVITE_STORAGE_KEY);
    if (invitation) this.setOrganizationInvitation(invitation);
  }

  @computed get isCurrentOrganizationSelfHosted() {
    return !!this.currentOrganization?.isSelfHostedK8s;
  }

  @action
  setCurrentOrganizationId(currentOrganizationId: string) {
    this.currentOrganizationId = currentOrganizationId;
    this.setLastVisitedOrganizationAndUserId(this.organizations[currentOrganizationId]);
    return currentOrganizationId;
  }

  switchCurrentOrganization(
    organizationId: string,
    newOrganization?: Organization,
    windowLocationPath = DEFAULT_NAVIGATE_PATH_TO_TEMPLATES
  ) {
    if (this.currentOrganizationId === organizationId) {
      history.push('/');
    } else {
      this.setLastVisitedOrganizationAndUserId(newOrganization ?? this.organizations[organizationId]);
      window.location.assign(windowLocationPath);
    }
  }

  @action
  setOrganizations(organizations: Organizations) {
    this.organizations = organizations;

    const localStorageOrganizationId = this.getOrganizationIdFromLocalStorage();
    const defaultOrganizationId = keys(organizations)[0];
    this.setCurrentOrganizationId(localStorageOrganizationId || defaultOrganizationId);
  }

  @action
  setIsDoneFetchingOrganizationsTrue() {
    this.isDoneFetchingOrganizations = true;
  }

  @action updateOrganization(update: Partial<Organization> & { id: string }) {
    const updatedOrganization = { ...this.organizations[update.id], ...update };

    this.setOrganizations({ ...this.organizations, [update.id]: updatedOrganization });

    if (update.id === this.currentOrganizationId) {
      this.setLastVisitedOrganizationAndUserId(updatedOrganization);
    }
  }

  @action setOrganizationInvitation(organization: OrganizationInvitation | null) {
    this.organizationInvitation = organization;
  }

  @action setInvitationError(error: Error) {
    this.invitationError = error.message;
  }

  @action setApiKeys(apiKeys: ApiKey[]) {
    this.apiKeys.replace(apiKeys);
  }

  @computed get currentOrganization() {
    return get(this.currentOrganizationId || '', this.organizations);
  }

  @computed get currentOrganizationName() {
    return get('name', this.currentOrganization) || '';
  }

  fetchOrganization = async (organizationId: string): Promise<Organization> => {
    if (!this.organizations[organizationId]) {
      const organization = await this.service.apiClient.organizations.getOrganization(organizationId);
      this.updateOrganization(organization);
    }

    return this.organizations[organizationId];
  };

  getOrganization = (organizationId: string): Organization | undefined => {
    return this.organizations[organizationId];
  };

  async getOrganizations() {
    const userId = this.getCurrentUserId();
    const { organization: cachedOrganization, userId: cachedUserId } = this.getLastVisitedOrganizationAndUserId() ?? {};

    const shouldWaitForLoad = !cachedOrganization || userId !== cachedUserId;
    const shouldLoadInBackground = !shouldWaitForLoad && isEmpty(this.organizations);

    if (shouldWaitForLoad) {
      this.clearLastVisitedOrganizationAndUserId();
      await this.fetchAllOrganizations();
    } else if (shouldLoadInBackground) {
      this.setOrganizations({ [cachedOrganization.id]: cachedOrganization });
      this.fetchAllOrganizations();
    }
  }

  private async fetchAllOrganizations() {
    const organizations = await this.service.apiClient.organizations.getOrganizations();
    const organizationsById = keyBy('id', organizations);
    this.setOrganizations(organizationsById);
    this.setIsDoneFetchingOrganizationsTrue();
  }

  async createOrganization(
    organization: NewOrganization,
    navigatePathAfterCreation = DEFAULT_NAVIGATE_PATH_TO_TEMPLATES
  ): Promise<Organization> {
    const result = await this.service.apiClient.organizations.createOrganization(organization);
    if (!result) throw new Error('Cannot create organization');

    await this.service.authStore.silentRenewSession();

    // createOrg doesn't return the role
    this.clearLastVisitedOrganizationAndUserId();

    this.switchCurrentOrganization(result.id, result, navigatePathAfterCreation);
    track(EventNames.ORGANIZATION_CREATED);
    return result;
  }

  async updateOrganizationDetails(organization: Organization) {
    const updatedOrg = await this.service.apiClient.organizations.updateOrganization(organization);
    this.updateOrganization(updatedOrg);
  }

  async getOrganizationUsers(params: { includeApiKeys?: boolean; query?: string }) {
    try {
      return await this.service.apiClient.organizations.getOrganizationUserList(
        this.currentOrganizationId as string,
        params
      );
    } catch (error) {
      console.error('Got error when getting organization users');
      console.error(error);
      throw error;
    }
  }

  async updateOrganizationPolicies(organizationId: string, policies: OrganizationApi.UpdatePolicy.Request.Body) {
    await this.service.apiClient.organizations.updateOrganizationPolicies(organizationId, policies);
    this.updateOrganization({ id: organizationId, ...policies });
  }

  async validateOrganizationInvitation(token: string) {
    try {
      const invitation = await this.service.apiClient.organizations.validateOrganizationInvitation(token);

      if (invitation) {
        await BrowserStorage.setItem(INVITE_STORAGE_KEY, invitation);
        this.setOrganizationInvitation(invitation);
      }

      return invitation;
    } catch (error) {
      this.setInvitationError(error as Error);
    }
  }

  async acceptOrganizationInvitationIfExists() {
    if (this.organizationInvitation) {
      return this.acceptOrganizationInvitation(this.organizationInvitation);
    }
  }

  async acceptOrganizationInvitation(invitation: OrganizationInvitation) {
    try {
      await this.service.apiClient.organizations.acceptOrganizationInvitation(invitation.token);

      localStorage.removeItem(INVITE_STORAGE_KEY);
      this.setOrganizationInvitation(null);
      await this.service.authStore.silentRenewSession();
      this.setCurrentOrganizationId(invitation.organizationId);
    } catch (error) {
      const alreadyAccepted = (error as Error).toString() === 'Invite already accepted';
      const userInOrg = invitation.organizationId in this.organizations;
      localStorage.removeItem(INVITE_STORAGE_KEY);

      if (!alreadyAccepted || !userInOrg) {
        throw error;
      }
    }
  }

  sendInvite = async (request: OrganizationApi.InviteRequest) => {
    await this.service.apiClient.organizations.sendInvite(request);
  };

  resendOrganizationInvitation = async (invitationId: string) => {
    this.service.networkStore.startNetwork(`org:invite:${invitationId}:resend`);

    try {
      await this.service.apiClient.organizations.resendOrganizationInvitation(invitationId);
    } finally {
      this.service.networkStore.endNetwork(`org:invite:${invitationId}:resend`);
    }
  };

  revokeOrganizationInvitation = async (invitationId: string) => {
    this.service.networkStore.startNetwork(`org:invite:${invitationId}:revoke`);

    try {
      await this.service.apiClient.organizations.revokeOrganizationInvitation(invitationId);
    } finally {
      this.service.networkStore.endNetwork(`org:invite:${invitationId}:revoke`);
    }
  };

  removeUser = async (organizationId: string, userId: string) => {
    this.service.networkStore.startNetwork(`org:${organizationId}:user:${userId}:delete`);

    try {
      await this.service.apiClient.organizations.deleteOrganizationUser(organizationId, userId);
    } catch (error) {
      console.error('Got error when deleting organization user');
      console.error(error);
      throw error;
    } finally {
      this.service.networkStore.endNetwork(`org:${organizationId}:user:${userId}:delete`);
    }
  };

  async updateRole(userId: string, role: RolesApi.RBACPermissionRole) {
    await this.service.apiClient.organizations.updateUserRole(this.currentOrganizationId as string, userId, role);
  }

  private getCurrentUserId() {
    return get('sub', this.service.userStore.profile);
  }

  private getLastVisitedOrganizationAndUserId(): { organization: Organization; userId: string } | undefined {
    return BrowserStorage.getItem(LAST_VISITED_ORGANIZATION_AND_USER_ID_STORAGE_KEY);
  }

  private setLastVisitedOrganizationAndUserId(organization: Organization) {
    const userId = this.getCurrentUserId();

    BrowserStorage.setItem(LAST_VISITED_ORGANIZATION_AND_USER_ID_STORAGE_KEY, {
      userId,
      organization
    });
  }

  private clearLastVisitedOrganizationAndUserId() {
    BrowserStorage.removeItem(LAST_VISITED_ORGANIZATION_AND_USER_ID_STORAGE_KEY);
  }

  getOrganizationIdFromLocalStorage() {
    const { organization, userId } = this.getLastVisitedOrganizationAndUserId() ?? {};

    return userId === this.getCurrentUserId() && organization && this.organizations[organization.id]
      ? organization.id
      : null;
  }

  loadApiKeys = async (organizationId: string) => {
    const apiKeys = await this.service.apiClient.configurations.getOrganizationApiKeys(organizationId);
    if (Array.isArray(apiKeys)) this.setApiKeys(apiKeys);
  };

  async addApiKey(organizationId: string, name: string, permissions: OrganizationApi.Permissions) {
    const apiKey = await this.service.apiClient.configurations.addOrganizationApiKey(organizationId, name, permissions);
    this.setApiKeys(this.apiKeys.concat({ ...apiKey, apiKeySecret: undefined }));
    return apiKey;
  }

  deleteApiKey = async (id: string) => {
    await this.service.apiClient.configurations.deleteOrganizationApiKey(id);
    this.setApiKeys(this.apiKeys.filter(apiKey => apiKey.id !== id));
  };

  async getWebhookSecret(webhookType: OrganizationApi.GetWebhookSecret.WebhookType) {
    const { webhookSecret } = await this.service.apiClient.organizations.getWebhookSecret(
      this.currentOrganizationId!,
      webhookType
    );

    return webhookSecret;
  }
}
