import React from 'react';
import styled from 'types/theme.types';
import { FormattedMessage } from 'react-intl';
import { TrialStatus, useTrialCountdown } from 'components/trial-countdown/use-trial-countdown';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { useTheme } from 'hooks/use-theme';

const TrialCountdown: React.FC = () => {
  const { status, message } = useTrialCountdown();
  const { appTheme } = useTheme();

  if (status === TrialStatus.EXPIRED)
    return (
      <TrialTextWrapper data-e2e="trial-expired-message" color={appTheme.errorRed}>
        <b>
          <FormattedMessage id="trial.expired" values={setLink(links.docs.BILLING.ROOT)} />
        </b>
      </TrialTextWrapper>
    );

  return (
    <b>
      <FormattedMessage id="trial.remaining.message" />
      <TrialTextWrapper
        data-e2e="trial-remaining-days"
        color={status === TrialStatus.CLOSE_TO_EXPIRE ? appTheme.warningYellowDarker : appTheme.textGray}>
        {message}
      </TrialTextWrapper>
    </b>
  );
};

const TrialTextWrapper = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

export default TrialCountdown;
