import React, { useCallback, useEffect, useMemo } from 'react';
import Select, { SelectOption } from 'components/common/input-components/select';
import { ReactComponent as AzureIcon } from 'assets/images/azure-logo.svg';
import { ReactComponent as GCPIcon } from 'assets/images/gcp-logo.svg';
import { ReactComponent as VaultIcon } from 'assets/images/vault-logo.svg';
import { ReactComponent as OCIIcon } from 'assets/images/oracle-logo.svg';
import { ReactComponent as Env0Icon } from 'assets/images/logo-without-text.svg';
import styled from 'types/theme.types';
import {
  SecretReferencePrefixes,
  getSecretReferenceId,
  isSecretReference,
  getSecretReferenceType,
  createSecretReference
} from '@env0/common-secret/secret-reference';
import { FormattedMessage } from 'react-intl';
import useStores from 'hooks/use-stores.hooks';
import { useIsSelfHosted } from 'hooks/use-is-self-hosted';
import AwsLogo from '../logos/aws-logo';

export enum SensitiveType {
  PLAIN_TEXT = 'plaintext',
  ENV0 = 'env0',
  VAULT = 'vault',
  SSM = 'ssm',
  AZURE = 'azure',
  GCP = 'gcp',
  OCI = 'oci'
}

const iconProps = { height: 16, width: 14 };
const VaultIconWithColor = styled(VaultIcon)`
  fill: #ffcf25;
`;

const sensitiveTypes: Record<
  SensitiveType,
  { icon?: React.ReactNode; prefix?: SecretReferencePrefixes; isExternalReference?: boolean }
> = {
  [SensitiveType.PLAIN_TEXT]: {},
  [SensitiveType.ENV0]: { icon: <Env0Icon {...iconProps} /> },
  [SensitiveType.VAULT]: {
    isExternalReference: true,
    prefix: SecretReferencePrefixes.VAULT,
    icon: <VaultIconWithColor {...iconProps} />
  },
  [SensitiveType.SSM]: {
    isExternalReference: true,
    prefix: SecretReferencePrefixes.AWS,
    icon: <AwsLogo {...iconProps} />
  },
  [SensitiveType.AZURE]: {
    isExternalReference: true,
    prefix: SecretReferencePrefixes.AZURE,
    icon: <AzureIcon {...iconProps} />
  },
  [SensitiveType.GCP]: {
    isExternalReference: true,
    prefix: SecretReferencePrefixes.GCP,
    icon: <GCPIcon {...iconProps} />
  },
  [SensitiveType.OCI]: {
    isExternalReference: true,
    prefix: SecretReferencePrefixes.OCI,
    icon: <OCIIcon {...iconProps} />
  }
} as const;

const allOptions = [
  SensitiveType.PLAIN_TEXT,
  SensitiveType.ENV0,
  SensitiveType.SSM,
  SensitiveType.AZURE,
  SensitiveType.GCP,
  SensitiveType.VAULT,
  SensitiveType.OCI
];

interface SecretReferenceDropdownProps {
  value: string;
  disabled?: boolean;
  onChange?: (stringValue: string, shouldEncrypt: boolean) => void;
  isEnv0Secret?: boolean;
  enablePlainText?: boolean;
}

const SecretReferenceDropdown: React.FC<SecretReferenceDropdownProps> = ({
  value,
  disabled,
  onChange,
  isEnv0Secret,
  enablePlainText,
  ...restProps
}) => {
  const {
    organizationsStore: {
      currentOrganization: { allowEnv0Secrets }
    }
  } = useStores();

  const { isAllowSelfHostedFeatures } = useIsSelfHosted();

  const secretType = useMemo(
    () => getSensitiveType({ value, isEnv0Secret, enablePlainText, allowEnv0Secrets }),
    [allowEnv0Secrets, enablePlainText, isEnv0Secret, value]
  );

  const options = useMemo(() => {
    if (enablePlainText) {
      return allOptions;
    }

    return allOptions.filter(option => option !== SensitiveType.PLAIN_TEXT);
  }, [enablePlainText]);

  const onChangeWithSetValue = useCallback(
    (newSecretTypeValue: SensitiveType) => {
      if (!onChange) {
        return;
      }

      const extractedValue = isSecretReference(value) ? getSecretReferenceId(value) : value;
      let newValue: string;
      if (newSecretTypeValue === SensitiveType.PLAIN_TEXT || newSecretTypeValue === SensitiveType.ENV0) {
        newValue = extractedValue;
      } else {
        newValue = createSecretReference(newSecretTypeValue as unknown as SecretReferencePrefixes, extractedValue);
      }

      const shouldEncrypt = newSecretTypeValue === SensitiveType.ENV0;

      if (value !== newValue || shouldEncrypt !== isEnv0Secret) {
        onChange(newValue, shouldEncrypt);
      }
    },
    [onChange, value, isEnv0Secret]
  );

  useEffect(() => {
    onChangeWithSetValue(secretType);
  }, [onChangeWithSetValue, secretType]);

  return (
    <StyledSelect value={secretType} disabled={disabled} onChange={onChangeWithSetValue} {...restProps}>
      {options.map((option: SensitiveType) => (
        <SelectOption
          key={option}
          value={option}
          disabled={isOptionDisabled(option, isAllowSelfHostedFeatures, !!allowEnv0Secrets)}>
          <OptionContainer>{renderOption(option)}</OptionContainer>
        </SelectOption>
      ))}
    </StyledSelect>
  );
};

const renderOption = (option: SensitiveType) => (
  <OptionText data-e2e={`sensitive-value-option-${option}`}>
    {sensitiveTypes[option].icon}
    <FormattedMessage id={`secret.types.${option}`} />
  </OptionText>
);

const isOptionDisabled = (
  option: SensitiveType,
  isAllowSelfHostedFeatures: boolean | undefined,
  allowEnv0Secrets: boolean
) => {
  if (option === SensitiveType.ENV0 && !allowEnv0Secrets) return true;
  if (sensitiveTypes[option].isExternalReference && !isAllowSelfHostedFeatures) return true;

  return false;
};

const getSensitiveType = ({
  value,
  isEnv0Secret = false,
  enablePlainText = false,
  allowEnv0Secrets = false
}: {
  value: string;
  allowEnv0Secrets?: boolean;
  enablePlainText?: boolean;
  isEnv0Secret?: boolean;
}): SensitiveType => {
  if (isSecretReference(value)) {
    const provider = getSecretReferenceType(value)!;
    const prefix = SecretReferencePrefixes[provider];
    return allOptions.find(sensitiveType => sensitiveTypes[sensitiveType].prefix === prefix)!;
  }

  if (isEnv0Secret) {
    return SensitiveType.ENV0;
  }

  if (enablePlainText) {
    return SensitiveType.PLAIN_TEXT;
  }

  if (allowEnv0Secrets) {
    return SensitiveType.ENV0;
  }

  // fallback to SSM
  return SensitiveType.SSM;
};

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OptionText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledSelect = styled(Select)`
  height: 28px;

  .ant-select-selection-item {
    line-height: 26px !important;
  }

  .ant-select-selector {
    height: 28px !important;
  }
`;

export default SecretReferenceDropdown;
