import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import type { RolesApi } from '@env0/role-service/api';
import type { RoleUpdate } from 'stores/rq/roles';

export type RoleFormValues = RoleUpdate;

export const schema: SchemaOf<RoleFormValues> = Yup.object({
  name: Yup.string().required().default(''),
  permissions: Yup.object({
    CREATE_CUSTOM_ROLES: Yup.boolean().default(false),
    VIEW_DASHBOARD: Yup.boolean().default(false),
    EDIT_DASHBOARD: Yup.boolean().default(false),
    RUN_PLAN: Yup.boolean().default(false),
    RUN_APPLY: Yup.boolean().default(false),
    ABORT_DEPLOYMENT: Yup.boolean().default(false),
    RUN_TASK: Yup.boolean().default(false),
    EDIT_ENVIRONMENT_SETTINGS: Yup.boolean().default(false),
    ARCHIVE_ENVIRONMENT: Yup.boolean().default(false),
    MANAGE_ENVIRONMENT_LOCK: Yup.boolean().default(false),
    OVERRIDE_MAX_TTL: Yup.boolean().default(false),
    CREATE_CROSS_PROJECT_ENVIRONMENTS: Yup.boolean().default(false),
    CREATE_PROJECT: Yup.boolean().default(false),
    VIEW_PROJECT: Yup.boolean().default(false),
    EDIT_PROJECT_SETTINGS: Yup.boolean().default(false),
    MANAGE_PROJECT_TEMPLATES: Yup.boolean().default(false),
    IMPORT_ENVIRONMENTS: Yup.boolean().default(false),
    VIEW_ORGANIZATION: Yup.boolean().default(false),
    EDIT_ORGANIZATION_SETTINGS: Yup.boolean().default(false),
    CREATE_AND_EDIT_TEMPLATES: Yup.boolean().default(false),
    OVERRIDE_MAX_ENVIRONMENT_PROJECT_LIMITS: Yup.boolean().default(false),
    CREATE_AND_EDIT_MODULES: Yup.boolean().default(false),
    VIEW_MODULES: Yup.boolean().default(false),
    READ_STATE: Yup.boolean().default(false),
    WRITE_STATE: Yup.boolean().default(false),
    FORCE_UNLOCK_WORKSPACE: Yup.boolean().default(false),
    MANAGE_BILLING: Yup.boolean().default(false),
    VIEW_AUDIT_LOGS: Yup.boolean().default(false),
    VIEW_PROVIDERS: Yup.boolean().default(false),
    CREATE_AND_EDIT_PROVIDERS: Yup.boolean().default(false),
    VIEW_ENVIRONMENT: Yup.boolean().default(false),
    ASSIGN_ROLE_ON_ENVIRONMENT: Yup.boolean().default(false),
    CREATE_VCS_ENVIRONMENT: Yup.boolean().default(false),
    EDIT_ALLOW_REMOTE_APPLY: Yup.boolean().default(false),
    EDIT_VCS_ENVIRONMENT: Yup.boolean().default(false),
    MOVE_ENVIRONMENTS: Yup.boolean().default(false),
    VIEW_DRIFT_CAUSE: Yup.boolean().default(false)
  })
});

type PermissionsSection<T extends RolesApi.RBACPermission> = {
  title: `roles.permissions.${Lowercase<T>}.title`;
  description: `roles.permissions.${Lowercase<T>}.description`;
  formKey: `permissions.${T}`;
};

const generatePermissionsSection = <T extends RolesApi.RBACPermission>(
  permissions: (T | null)[]
): PermissionsSection<T>[] => {
  return (permissions.filter(permission => !!permission) as T[]).map(permission => {
    return {
      title: `roles.permissions.${permission.toLowerCase() as Lowercase<T>}.title`,
      description: `roles.permissions.${permission.toLowerCase() as Lowercase<T>}.description`,
      formKey: `permissions.${permission}`
    };
  });
};

export const permissionsSections: {
  title: string;
  permissions: PermissionsSection<RolesApi.RBACPermission>[];
}[] = [
  {
    title: 'roles.section.deployment-permissions',
    permissions: generatePermissionsSection([
      'RUN_PLAN',
      'RUN_APPLY',
      'ABORT_DEPLOYMENT',
      'RUN_TASK',
      'CREATE_VCS_ENVIRONMENT'
    ])
  },
  {
    title: 'roles.section.environment-permissions',
    permissions: generatePermissionsSection([
      'EDIT_ENVIRONMENT_SETTINGS',
      'EDIT_VCS_ENVIRONMENT',
      'ARCHIVE_ENVIRONMENT',
      'MANAGE_ENVIRONMENT_LOCK',
      'OVERRIDE_MAX_TTL',
      'OVERRIDE_MAX_ENVIRONMENT_PROJECT_LIMITS',
      'VIEW_ENVIRONMENT',
      'ASSIGN_ROLE_ON_ENVIRONMENT',
      'VIEW_DRIFT_CAUSE'
    ])
  },
  {
    title: 'roles.section.project-permissions',
    permissions: generatePermissionsSection([
      'VIEW_PROJECT',
      'EDIT_PROJECT_SETTINGS',
      'MANAGE_PROJECT_TEMPLATES',
      'CREATE_PROJECT',
      'IMPORT_ENVIRONMENTS'
    ])
  },
  {
    title: 'roles.section.organization-permissions',
    permissions: generatePermissionsSection([
      'VIEW_ORGANIZATION',
      'EDIT_ORGANIZATION_SETTINGS',
      'CREATE_AND_EDIT_TEMPLATES',
      'CREATE_AND_EDIT_MODULES',
      'CREATE_AND_EDIT_PROVIDERS',
      'CREATE_CROSS_PROJECT_ENVIRONMENTS',
      'VIEW_MODULES',
      'VIEW_PROVIDERS',
      'CREATE_CUSTOM_ROLES',
      'VIEW_DASHBOARD',
      'EDIT_DASHBOARD',
      'VIEW_AUDIT_LOGS',
      'MANAGE_BILLING',
      'MOVE_ENVIRONMENTS'
    ])
  },
  {
    title: 'roles.section.remote-backend-permissions',
    permissions: generatePermissionsSection([
      'READ_STATE',
      'WRITE_STATE',
      'FORCE_UNLOCK_WORKSPACE',
      'EDIT_ALLOW_REMOTE_APPLY'
    ])
  }
];
