import type { Blueprint, ConfigurationProperty, DeploymentLog, Environment } from 'types/api.types';
import { ConfigurationScope } from 'types/api.types';

import type { RunEnvironmentArgs } from 'utils/environment.utils';
import { isInProgressOrWaiting } from 'utils/environment.utils';
import * as logger from 'utils/logger.utils';
import type { NetworkStore } from 'stores/mobx/network.store';
import type { EnvironmentsStore } from 'stores/mobx/environments.store';
import { EventNames, track } from 'utils/analytics.utils';
import type { DeployRequest, EnvironmentApi } from '@env0/environment-service/api';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { getEnqueuedDeploymentRedirectUrl, getInProgressDeploymentRedirectUrl } from 'utils/deploy-redirect.utils';

interface StoresInUse {
  networkStore: NetworkStore;
  environmentsStore: EnvironmentsStore;
}

interface DeployCommons extends StoresInUse {
  environmentName: string;
  blueprintRevision?: string;
  comment?: string;
  changedConfigurationProperties: ConfigurationProperty[];
  configurationSetChanges: EnvironmentApi.ConfigurationSetChanges;
  ttlRequest?: EnvironmentApi.TTLRequest;
  subEnvironments?: RunEnvironmentArgs['subEnvironments'];
  workflowDeploymentOptions?: EnvironmentApi.Deploy.Request.Body['workflowDeploymentOptions'];
  targets?: string[];
}

export interface DestroyArguments extends StoresInUse {
  environment: Environment;
  requiresApproval: boolean;
  skipStateRefresh: boolean;
  navigate: (path: string) => void;
  projectId: string;
  checkoutUpdatedCode: boolean;
  revision?: string;
  comment?: string;
}

export type SingleUseBlueprint = Omit<Blueprint, 'id' | 'author'>;

export interface DeployArguments extends DeployCommons {
  organizationId: string;
  projectId: string;
  blueprint: Blueprint | SingleUseBlueprint;
  navigate: (path: string) => void;
  approveAutomatically: boolean;
  workspaceName?: string;
  ttlRequest?: EnvironmentApi.TTLRequest;
  continuousDeployment?: boolean;
  pullRequestPlanDeployments?: boolean;
  driftDetectionRequest?: EnvironmentApi.DriftDetectionRequest;
  terragruntWorkingDirectory?: string;
  isRemoteBackend?: boolean;
  isSingleUse?: boolean;
  k8sNamespace?: string;
}

export interface DeployToEnvironmentArguments extends DeployCommons {
  environmentId: string;
  blueprintId: string;
  updateEnvironment: boolean;
  requiresApproval?: boolean;
}

export interface RedeployEnvironmentArgs extends DeployCommons {
  environment: Environment;
  projectId: string;
  requiresApproval: boolean;
  navigate: (path: string) => void;
  subEnvironments?: RunEnvironmentArgs['subEnvironments'];
  workflowDeploymentOptions?: EnvironmentApi.Deploy.Request.Body['workflowDeploymentOptions'];
  blueprintId?: string;
}

type CommonDeployEnvironmentArgs = {
  blueprintRevision: string | undefined;
  comment: string | undefined;
  environmentsStore: EnvironmentsStore;
  projectId: string;
  environmentName: string;
  approveAutomatically: boolean;
  ttlRequest?: EnvironmentApi.TTLRequest;
  workspaceName: string | undefined;
  changedConfigurationProperties: ConfigurationProperty[];
  configurationSetChanges: EnvironmentApi.ConfigurationSetChanges;
  continuousDeployment: undefined | boolean;
  pullRequestPlanDeployments: undefined | boolean;
  terragruntWorkingDirectory: string | undefined;
  driftDetectionRequest: EnvironmentApi.DriftDetectionRequest | undefined;
  blueprint: Blueprint | SingleUseBlueprint;
  isRemoteBackend?: boolean;
  subEnvironments?: RunEnvironmentArgs['subEnvironments'];
  isSingleUse?: boolean;
  k8sNamespace?: string;
  targets?: string[];
};

const redirectToEnvironmentPage = async ({
  navigate,
  environment,
  environmentsStore,
  projectId
}: Pick<RedeployEnvironmentArgs, 'navigate' | 'environment' | 'environmentsStore' | 'projectId'>) => {
  const isDeploymentExecutedImmediately = !isInProgressOrWaiting(environment.status);
  if (isDeploymentExecutedImmediately) {
    navigate(getInProgressDeploymentRedirectUrl(environment.latestDeploymentLog, environment.id, projectId));
  } else {
    environmentsStore.loadEnvironmentDeployments(environment.id);
    navigate(getEnqueuedDeploymentRedirectUrl(environment.id, projectId));
  }
};

async function deployEnvironment({
  projectId,
  environmentName,
  blueprintRevision,
  comment,
  blueprint,
  changedConfigurationProperties,
  configurationSetChanges,
  approveAutomatically,
  driftDetectionRequest,
  ttlRequest,
  workspaceName,
  continuousDeployment,
  environmentsStore,
  pullRequestPlanDeployments,
  terragruntWorkingDirectory,
  isRemoteBackend,
  subEnvironments,
  isSingleUse,
  k8sNamespace,
  targets
}: CommonDeployEnvironmentArgs) {
  const deployRequest = {
    blueprintRevision: blueprintRevision || blueprint.revision,
    blueprintId: get(blueprint, 'id'),
    comment,
    subEnvironments,
    targets
  } as DeployRequest & { blueprintId: string };

  const createEnvFunction = isSingleUse
    ? environmentsStore.deployEnvironmentWithoutTemplate
    : environmentsStore.createEnvironment;
  return await createEnvFunction({
    ...omit(blueprint, ['name']),
    terraformVersion: blueprint.terraformVersion!,
    projectId,
    deployRequest,
    name: environmentName,
    requiresApproval: !approveAutomatically,
    ttl: ttlRequest,
    workspaceName: workspaceName,
    configurationChanges: changedConfigurationProperties,
    configurationSetChanges,
    continuousDeployment,
    pullRequestPlanDeployments,
    terragruntWorkingDirectory,
    driftDetectionRequest,
    isRemoteBackend,
    k8sNamespace
  });
}

export const deployTemplate = async ({
  projectId,
  environmentName,
  blueprintRevision,
  comment,
  blueprint,
  changedConfigurationProperties,
  configurationSetChanges,
  navigate,
  approveAutomatically,
  driftDetectionRequest,
  ttlRequest,
  workspaceName,
  continuousDeployment,
  networkStore,
  environmentsStore,
  pullRequestPlanDeployments,
  terragruntWorkingDirectory,
  isRemoteBackend,
  subEnvironments,
  isSingleUse,
  k8sNamespace,
  targets
}: DeployArguments) => {
  networkStore.startNetwork('deploy:template');

  try {
    const environment = await deployEnvironment({
      blueprintRevision,
      comment,
      blueprint,
      environmentsStore,
      projectId,
      environmentName,
      approveAutomatically,
      ttlRequest,
      workspaceName,
      changedConfigurationProperties,
      configurationSetChanges,
      continuousDeployment,
      pullRequestPlanDeployments,
      terragruntWorkingDirectory,
      driftDetectionRequest,
      isRemoteBackend,
      subEnvironments,
      isSingleUse,
      k8sNamespace,
      targets
    });

    track(
      EventNames.CREATED_ENVIRONMENT,
      omitBy(
        {
          environmentName,
          environmentId: environment.id,
          organizationId: environment.organizationId,
          isSingleUse,
          isSingleUseWithImport: isSingleUse ? !!workspaceName : null
        },
        isNil
      )
    );

    navigate(`/p/${projectId}/environments/${environment.id}`);
  } finally {
    networkStore.endNetwork('deploy:template');
  }
};

export const destroyEnvironment = async ({
  environment,
  requiresApproval,
  skipStateRefresh,
  navigate,
  projectId,
  checkoutUpdatedCode,
  revision,
  networkStore,
  environmentsStore,
  comment
}: DestroyArguments) => {
  if (!isInProgressOrWaiting(environment.status)) {
    networkStore.startNetwork(`environment:${environment.id}:destroy`);
  }
  try {
    const deploymentLogPromise = environmentsStore.destroyEnvironment(environment, {
      checkoutUpdatedCode,
      skipStateRefresh,
      revision,
      comment,
      requiresApproval: requiresApproval !== environment.requiresApproval ? requiresApproval : undefined
    });

    deploymentLogPromise.then(deploymentLog =>
      environmentsStore.storeLastTriggeredDeployment(environment.id, deploymentLog)
    );

    await redirectToEnvironmentPage({ navigate, environment, environmentsStore, projectId });

    return deploymentLogPromise;
  } catch (error) {
    logger.error(error);
  } finally {
    networkStore.endNetwork(`environment:${environment.id}:destroy`);
  }
};

export const redeployEnvironment = async ({ ...args }: RedeployEnvironmentArgs) => {
  return deployToEnvironment({ internalDeployType: 'redeploy', ...args });
};

export const destroySingleSubEnvNode = async ({
  ...args
}: RedeployEnvironmentArgs & Required<Pick<RedeployEnvironmentArgs, 'workflowDeploymentOptions'>>) => {
  return deployToEnvironment({ internalDeployType: 'destroySingleSubEnv', ...args });
};

const postDeploymentToEnvironment = async ({
  environmentId,
  blueprintId,
  changedConfigurationProperties,
  configurationSetChanges,
  updateEnvironment,
  environmentName,
  blueprintRevision,
  comment,
  requiresApproval,
  ttlRequest,
  environmentsStore,
  subEnvironments,
  workflowDeploymentOptions,
  targets
}: DeployToEnvironmentArguments) => {
  const environmentConfigurationProperties = changedConfigurationProperties.map(propertyData => ({
    ...propertyData,
    scope: ConfigurationScope.DEPLOYMENT
  }));

  const deployment = await environmentsStore.deployToEnvironment(
    environmentId,
    blueprintId,
    blueprintRevision,
    comment,
    environmentConfigurationProperties,
    configurationSetChanges,
    ttlRequest,
    updateEnvironment ? environmentName : undefined,
    requiresApproval,
    subEnvironments,
    workflowDeploymentOptions,
    targets
  );

  track(EventNames.DEPLOYED_ENVIRONMENT, {
    environmentId,
    environmentName
  });

  return deployment;
};

const deployToEnvironment = async ({
  environment,
  networkStore,
  environmentsStore,
  environmentName,
  requiresApproval,
  navigate,
  projectId,
  internalDeployType,
  blueprintId,
  ...deployArgs
}: RedeployEnvironmentArgs & {
  internalDeployType: 'redeploy' | 'destroySingleSubEnv';
}) => {
  networkStore.startNetwork(`environment:${environment.id}:${internalDeployType}`);
  try {
    const toDeploy: DeployToEnvironmentArguments = {
      environmentId: environment.id,
      blueprintId: blueprintId || environment.latestDeploymentLog.blueprintId,
      updateEnvironment: environment.name !== environmentName || environment.requiresApproval !== requiresApproval,
      environmentName,
      requiresApproval,
      networkStore,
      environmentsStore,
      ...deployArgs
    };

    const deployPromise = postDeploymentToEnvironment(toDeploy).then(deploymentLog =>
      environmentsStore.storeLastTriggeredDeployment(environment.id, deploymentLog)
    );

    await redirectToEnvironmentPage({ navigate, environment, environmentsStore, projectId });
    return deployPromise;
  } finally {
    networkStore.endNetwork(`environment:${environment.id}:${internalDeployType}`);
  }
};

type RemediateDriftProps = {
  environmentsStore: EnvironmentsStore;
  environment: Environment;
  deploymentLog: DeploymentLog;
  navigate: (path: string) => void;
};

export const remediateDrift = async ({
  environmentsStore,
  environment,
  deploymentLog,
  navigate
}: RemediateDriftProps) => {
  await environmentsStore.remediateDrift(environment.id, deploymentLog.id);
  await redirectToEnvironmentPage({ navigate, environment, environmentsStore, projectId: environment.projectId });
};
